@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.sectionHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 0 6rem 0;
  width: 100%;
  @include media(lg) {
    flex-direction: row-reverse;
    padding: 5rem 0 6rem 0;
  }
  .sectionHeader-image {
    position: relative;
    width: 100%;
    box-shadow: 0 8px 65px rgba(0, 0, 0, 0.1);
    @include media(xl) {
      @include flex(0 1 67%);
      max-width: 67%;
      @include flex-block;
      @include justify-content(center);
      @include align-items(center);
      overflow: hidden;
    }
    @include media(xxl) {
      @include flex(0 1 68.5%);
      max-width: 68.5%;
    }
    &:before {
      content: "";
      display: block;
      padding-top: 86.5%;
      @include media(md) {
        padding-top: 52.5%;
      }
      @include media(xl) {
        padding-top: 45%;
      }
      @include media(xxl) {
        padding-top: 45%;
      }
    }
    &-inner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      @include media(xl) {
        top: -5rem;
        left: -10rem;
        width: calc(100% + 10rem);
        height: calc(100% + 10rem);
        animation: imageSlide 20s linear forwards;
        will-change: transform;
      }
    }
  }
  .sectionHeader-content {
    padding: 0 0 4rem 0;
    @include media(lg) {
      padding: 7rem 3.5rem 3.5rem 3.5rem;
    }
    h1 {
      position: relative;
      z-index: 1;
      left: 0;
      text-shadow: 0 0 38px rgba(0, 0, 0, 0.1);
      margin-bottom: 3rem;
      opacity: 0;
      animation: titleFadeIn 750ms linear forwards;
      animation-delay: 500ms;
      @include media(xl) {
        left: -9.5rem;
      }
    }
    .sectionHeader-text {
      opacity: 0;
      animation: textFadeIn 750ms linear forwards;
      animation-delay: 1.5s;
      @include media(lg) {
        max-width: 350px;
      }
    }
  }
}

@keyframes imageSlide {
  0% {
      transform: translate3d(10rem, 0,0);
  }
  100% {
      transform: translate3d(0,0,0);
  }
}

@keyframes textFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes titleFadeIn {
  0% {
    top: -5rem;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}
