.partners {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
  margin-bottom: 12rem;
  box-shadow: 0 8px 65px rgba(0, 0, 0, 0.1);
}

.partners-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  background-color: $base-white;
  min-height: 36rem;
  padding: 3rem 4rem 7rem 4rem;
  transition: box-shadow ease 300ms;
  @include media(md) {
    max-width: 33.3333%;
  }
  @include media(xxl) {
    padding: 3rem 4rem 9rem 4rem;
  }
  &:hover {
    z-index: 2;
    box-shadow: 0 8px 65px rgba(0, 0, 0, 0.2);
    .partners-link {
      &:after {
        left: 15px;
      }
     }
  }
  .partners-image {
    margin-bottom: 4rem;
  }
  .partners-link {
    color: #e5004c;
    font-style: italic;
    @include pux-scale-with-min("font-size", 20px, 15px);
    font-weight: 400;
    letter-spacing: 0.3px;
    &:after {
      position: relative;
      left: 10px;
      content: '\e902';
      @include pux-scale-with-min("font-size", 16px, 11px);
      transition: left ease 300ms;
    }
  }
}