@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.topImage {
  position: relative;
  background-size: cover !important;
  background-position: 50% 0 !important;
  background-repeat: no-repeat !important;

  @include media(xl) {
    background-position: 50% 50% !important;
    height: 77rem;
  }

  .pux-container {
    padding-left: 0px;
    padding-right: 0px;
    @include media(xl) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .topImage-content-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 0 35px 0;
    @include media(xl) {
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      height: 77rem;
      padding: 35px 0;
    }
  }

  .topImage-image {
    position: relative;
    z-index: 2;
    max-width: 96rem;
    animation: topImageSlide 10s linear forwards;
    @include media(xxs, lg) {
      display: none;
    }
    &-respo {
      width: 100%;
      @include media(xl) {
        display: none;
      }
      img {
        width: 100%;
      }
    }
  }
  
  .topImage-text {
    position: relative;
    z-index: 3;
    flex-shrink: 0;
    background-color: #457388;
    width: 100%;
    padding: 4rem 4rem 4rem 8rem;
    margin-bottom: -35px;
    @include media(xl) {
      background-color: transparent;
      width: auto;
      padding: 0;
    }
    .topImage-product-name {
      @include pux-scale-with-min("font-size", 66px, 44px);
      @include pux-scale-with-min("line-height", 70px, 45px);
      text-transform: uppercase;
      font-weight: 400;
      color: $base-white;
    }
    .topImage-product-border {
      background-color: $base-white;
      width: 141px;
      height: 5px;
      margin: 2rem 0 2.3rem 0;
    }
    .topImage-product-description {
      @include pux-scale-with-min("font-size", 18px, 16px);
      @include pux-scale-with-min("line-height", 24px, 20px);
      color: $base-white;
      max-width: 466px;
      width: 100%;
      margin-bottom: 4rem;
      opacity: 0;
      animation: topImageFadeIn 750ms linear forwards;
      animation-delay: 1s;
    }
    .btn-primary {
      @include pux-scale-with-min("font-size", 20px, 16px);
      border-radius: 43px;
      padding: 3.5rem 5.5rem 3.5rem 3.5rem;
      opacity: 0;
      animation: topImageFadeIn 750ms linear forwards;
      animation-delay: 1.5s;
      &:after {
        position: relative;
        top: 1px;
        left: 10px;
        margin-top: 0;
      }
    }
  }
}

@keyframes topImageSlide {
  0% {
    transform: translate3d(-5rem, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes topImageFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
