@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

//Salers
.salers-stores {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  padding: 4rem 0 8rem 0;
  @include media (md) {
    margin: 0;
  }
  @include media (lg) {
    flex-wrap: nowrap;
  }
  &-map {
    display: flex;
    width: 100%;
    @include media (lg) {
      width: 66%;
    }
    &-box {
      width: 100%;
      min-height: 60rem;
      margin-top: 3.5rem;
      @include media(lg) {
        margin-top: 0;
      }
    }
    &-item {
      width: 100%;
      height: 100%;
      border-radius: 0.4rem;
      background-size: cover !important;
      background-repeat: no-repeat !important;
    }
}
  &-list {
    display: block;
    width: 100%;
    padding: 0 2.5rem;
    @include media (md) {
      padding: 0;
    }
    @include media (lg) {
      width: 33%;
      margin: 0 3.5rem 0 0;
    }
    &-search,
    &-results {
      background: $base-white;
    }
    &-search {
      margin-bottom: 3.4rem;
      padding: 2.5rem;
      display: flex;
      flex-wrap: nowrap;
      &-txt {
        width: calc(100% - 5.6rem);
        padding: 1.7rem;
        background: $color-grey;
        outline: none;
        border: none;
        color: $base-black;
      }
      &-btn {
        padding: 1.7rem;
        margin: auto;
        cursor: pointer;
        &:before {
          content: "\e902";
          color: $base-white;
          font-weight: 300;
          @include pux-scale-with-min("font-size", 22px, 18px);
        }
      }
    }
    &-results {
      padding: 3.5rem 3.5rem 0.5rem 3.5rem;
    }
  }
  &-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    padding: 2.3rem 0;
    border-bottom: 0.5px $color-grey solid;
    &-address {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-right: 1rem;
      width: 100%;
      b {
        color: $main-color;
      }
      .btn {
        align-self: flex-end;
        padding: 1rem 0;
        @include pux-scale-with-min("font-size", 14px, 12px);
        margin: 0.5rem 0 0 0;
      }
    }
  }
}

#map-seznam {
  width: 100%;
  height: 100%;
  min-height: 100rem;
  img {
    max-width: none !important;
  }
  .map-pin {
    position: relative;
    height: 55px;
    width: 55px;
    &:focus,
    &:hover {
      z-index: 30;
    }
  }
  .card-header:empty,
  .card-footer:empty {
    display: none;
  }
  .card {
    overflow: unset;
    max-width: 350px !important;
    padding: 4rem 5.5rem 6rem 5.5rem !important;
    border: none !important;
    border-radius: 0;
    margin-left: 152px;
    margin-bottom: 37px;
    .card-body {
      padding: 0;
      flex: none;
    }
    .close {
      top: 10px !important;
      right: 10px !important;
      font-size: 20px;
      color: $main-color;
      opacity: 1 !important;
      &:hover {
        color: darken($main-color, 10);
      }
      &:after {
        display: none !important;
      }
      &:before {
        content: '\2716' !important;
      }
    }
  }
  .zoom .zoom-menu {
    top: 32rem !important;
    @include media(md) {
      top: 26px !important;
    }
  }
  .button-group {
    top: 70rem;
    @include media(md) {
      top: auto;
    }
  }
  .compass.compass-north {
    top: 79rem !important;
    @include media(md) {
      top: 50px !important;
    }
  }
}

.pux-cluster {
  div {
    background: $main-color;
    border: none !important;
    color: $base-white !important;
    width: 82px !important;
    height: 82px !important;
  }
}

.map-points {
  display: none;
}

.dealers-item-data {
  color: #000;
  font-size: 17px;
  font-weight: 200;
  line-height: 32px;
  a{
    color: $main-color;
    text-decoration: underline;
    &:focus,
    &:hover{
      text-decoration: none;
    }
  }
}

.dealers-item-data-name {
  font-size: 20px;
  font-weight: 500;
}

// #select-region {
//   option {
//     &[data-cities*="["] {
//       display: block !important;
//     }
//   }
// }


.holder {
	margin: 1.5rem 0;
}

.holder {
  $pagerFontSizeMax: 15px;
  $pagerFontSizeMin: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include pux-scale-with-min("font-size", $pagerFontSizeMax, $pagerFontSizeMin);
  .jp-first, .jp-previous, .jp-next, .jp-last {
    position: relative;
    display: block;
    width: 20px;
    color: $main-color;
    opacity: 1;
    @include pux-scale-with-min("height", $pagerFontSizeMax, $pagerFontSizeMin);
    transition: opacity ease 300ms, color ease 300ms;
    &:hover {
      color:darken($main-color, 5);
    }
    &.jp-disabled {
      opacity: 0;
      cursor: default;
    }
  }
  a {
    margin: 0 8px;
    &.jp-current {
      font-weight: 700;
    }
    &:hover {
      font-weight: 700;
      color: $base-black;
    }
  }
  .jp-first {
    margin: 0 2rem 0 1rem;
    &:before {
      position: absolute;
      bottom: 1px;
      left: 0;
      content: "\e902";
      transform: scaleX(-1);
    }
    &:after {
      position: absolute;
      bottom: 1px;
      right: -2px;
      content: "\e902";
      transform: scaleX(-1);
    }
  }
  .jp-previous {
    margin: 0 2rem 0 0;
    &:before {
      position: absolute;
      bottom: 1px;
      left: 0;
      content: "\e902";
      transform: scaleX(-1);
    }
  }
  .jp-next {
    margin: 0 0 0 2rem;
    &:after {
      position: absolute;
      bottom: 1px;
      right: 0;
      content: "\e902";
    }
  }
  .jp-last {
    margin: 0 1rem 0 2rem;
    &:before {
      position: absolute;
      bottom: 1px;
      left: -2px;
      content: "\e902";
    }
    &:after {
      position: absolute;
      bottom: 1px;
      right: 0;
      content: "\e902";
    }
  }
}
