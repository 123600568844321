
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";


.cms-bootstrap [class^="icon-"]:before, .cms-bootstrap [class*=" icon-"]:before {
    font-family: 'Core-icons' !important;
}

.EditMode {
    .base-layout-title,
    .search-layout-title,
    .category-title,
    .hp-category-title {
        z-index: 99 !important;
    }
}

*:before,
*:after{
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.helper-4k{
  max-width: 200rem;
  margin:auto;
}

html {
		font-size: $scale-base-size*$scale-ratio-xxs;
		@include media(xs) {
				font-size: $scale-base-size*$scale-ratio-xs;
		}
		@include media(sm) {
				font-size: $scale-base-size*$scale-ratio-sm;
		}
		@include media(md) {
				font-size: $scale-base-size*$scale-ratio-md;
		}
		@include media(lg) {
				font-size: $scale-base-size*$scale-ratio-lg;
		}
		@include media(xl) {
				font-size: $scale-base-size*$scale-ratio-xl;
		}
		@include media(xxl) {
				font-size: $scale-base-size*$scale-ratio-xxl;
		}
}

@media only screen and( -webkit-min-device-pixel-ratio: 1.25 ),
       only screen and(      -o-min-device-pixel-ratio: 5/4 ),
       only screen and( min-resolution: 120dpi ),
       only screen and( min-resolution: 1.25dppx ) {
		html {
				font-size: $scale-base-size*$scale-ratio-xxs;
				@include media(xs) {
						font-size: $scale-base-size*$scale-ratio-xs;
				}
				@include media(sm) {
						font-size: $scale-base-size*$scale-ratio-sm;
				}
				@include media(md) {
						font-size: $scale-base-size*$scale-ratio-md;
				}
				@include media(lg) {
						font-size: $scale-base-size*$scale-ratio-lg;
				}
				@include media(xl) {
						font-size: $scale-base-size*$scale-ratio-xl*0.8;
				}
				@include media(xxl) {
						font-size: $scale-base-size*$scale-ratio-xxl*0.8;
				}
		}
}

form,
html,
body{
    font-family:$font-family;
    height:100%;
    color: $base-black;
}

body{
	 @include pux-scale-with-min("font-size", 20px, 16px);
}

img{
    max-width:100%;
    height:auto !important;
}

@each $value in $px-values {
  .pt-#{strip-unit($value)} {
			@include pux-scale-with-min("padding-top", $value, 20px);
  }

  .pb-#{strip-unit($value)} {
			@include pux-scale-with-min("padding-bottom", $value, 20px);
  }

  .mt-#{strip-unit($value)} {
			@include pux-scale-with-min("margin-top", $value, 20px);
  }

  .mb-#{strip-unit($value)} {
			@include pux-scale-with-min("margin-bottom", $value, 20px);
  }
}

h1,
.h1{
  @include pux-scale-with-min("font-size", 80px, 32px);
  @include pux-scale-with-min("line-height", 90px, 36px);
  text-transform: uppercase;
  font-weight: 800;
  color: $base-white;
  &.letter-spac {
      letter-spacing: 0.3rem;
  }
  &.category-banner-title {
      font-size: 40px;
      line-height: 1.2;
      @include media(md) {
          font-size: 60px;
      }
      @include media(xl) {
          font-size: rem(xl, 80px);
      }
      @include media(xxl) {
          font-size: 10rem;
      }
  }
}

h2,
.h2{
  @include pux-scale-with-min("font-size", 50px, 25px);
  @include pux-scale-with-min("line-height", 60px, 25px);
  text-transform: uppercase;
  font-weight: 800;
  color: $base-white;
  &.letter-spac {
      letter-spacing: 0.3rem;
  }
}

h3,
.h3{
    @include pux-scale-with-min("font-size", 30px, 20px);
    @include pux-scale-with-min("line-height", 30px, 20px);
    text-transform: uppercase;
    &.font-strong {
        font-weight: 800;
        color: $base-white;
    }
}

h4,
.h4{
  font-weight: 800;
  color: $base-black;
  text-transform: uppercase;
  @include pux-scale-with-min("font-size", 22px, 18px);
  line-height: 1.2;
  &.font-strong {
      font-weight: 800;
      color: $base-white;
  }
}

big,
.perex{
  @include pux-scale-with-min("font-size", $font-size-perex, $min-font-size-perex);
  @include pux-scale-with-min("line-height", $line-height-perex, $min-line-height-perex);
}

small,
.text-small{
  @include pux-scale-with-min("font-size", $font-size-small, $min-font-size-small);
  @include pux-scale-with-min("line-height", $line-height-small, $min-line-height-small);
}

a{
    color:$color-link;
    cursor: pointer;
    &:focus,
    &:hover{
      color: darken($color-link, 10);
      outline:none;
      text-decoration: none;
    }
}

.btn{
  display: inline-block;
  border-radius: 30px;
  border: none;
  //padding: 1rem 3.5rem;
  box-shadow: none;
  @include pux-scale-with-min("font-size", 22px, 18px);
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0.1rem;
  &:focus,
  &:hover{
      text-decoration: none;
      box-shadow: none;
  }
}

.btn-primary{
  background: $btn-primary-background;
  color: $btn-primary-color;
  &:focus,
  &:hover{
      text-decoration: none;
      background: transparent;
      color: $btn-primary-color;
  }
  &.small-size {
      //padding: 1rem 2.4rem;
      @include pux-scale-with-min("font-size", 20px, 18px);
  }
  &:after {
      content: "\e902";
      display: inline-block;
      font-size: 66%;
      transform: translate(3px, 0rem);
      @include transition;
      margin-top: 3%;
  }
  &:hover,
  &:focus {
      &:after {
          transform: translate(6px, 0rem);
      }
  }
}

.btn-default{
  background: $btn-default-background;
  color: $btn-default-color;
  &:focus,
  &:hover{
      text-decoration: none;
      background: darken($btn-default-background, 5);
      color: $btn-default-color;
  }
}

/*
.btn-secondary {
  background: $base-white;
  color: $base-black;
  font-size: 14px;
  font-weight: 600;
  @include pux-scale-from("font-size", lg, 14px);
  //padding: 1rem 2.3rem;
  letter-spacing: 0;
  &:focus,
  &:hover{
      text-decoration: none;
      background: $btn-default-background;
      color: $base-white;
  }
}
*/
.arrow-btn {
    &:after {
        content: "\e902";
        display: inline-block;
        font-size: 66%;
        transform: translate3d(0.2rem, -0.1rem, 0);
        @include transition;
    }
    &:hover,
    &:focus {
        color: lighten($base-black, 10%);
        &:after {
            transform: translate3d(0.6rem, -0.1rem, 0);
        }
    }
}

.btn-gray,
.btn-secondary {
  //background: $body-bg;
  color: $base-black;
  font-size: 12px;
  font-weight: 600;
  @include pux-scale-with-min("font-size", 20px, 16px);
  padding: 1rem 0; //1rem 2.3rem
  letter-spacing: 0;
  &:after {
      content: "\e902";
      display: inline-block;
      font-size: 66%;
      transform: translate(3px, 0rem);
      @include transition;
      margin-top: 3%;
  }
  &:focus,
  &:hover{
      text-decoration: none;
      //background: darken($body-bg, 5);
      color: $base-black;
      &:after {
          transform: translate(6px, 0rem);
      }
  }
  &.big-size {
      padding: 1rem 2.4rem;
      font-weight: 400;
      @include pux-scale-with-min("font-size", 22px, 18px);
  }
}

.btn-light-gray {
  background: transparent;
  color: $base-black;
  font-weight: 600;
  padding: 1rem 2.3rem;
  letter-spacing: 0;
  @include pux-scale-with-min("font-size", 20px, 16px);
  &:focus,
  &:hover{
    text-decoration: underline;
    //background: darken($body-bg, 5);
    color: $base-black;
  }
}

.cross-icon {
  display: inline-flex;
  @include align-items(center);
  &:before {
    content: "\e91b";
    display: block;
    margin-right: 1rem;
    font-size: 8px;
    font-weight: 600;
    @include pux-scale-from("font-size", lg, 8px);
  }
}

.btn-panel {
  background: $base-white;
  color: $base-black;
  font-size: 12px;
  font-weight: 600;
  padding: 1rem 2rem;
  margin-left: 2rem;
  letter-spacing: 0;
  @include pux-scale-from("font-size", xl, 14px);
  @include media(xxl) {
      padding: 1rem 2.8rem;
      font-size: rem(xxl, 15px)
  }
  &-inner {
    @include flex-block;
    @include align-items(center);
    &:after {
      content: "\e901";
      display: inline-block;
      font-size: 7px;
      color: $base-black;
      margin-left: 10px;
      @include transition;
      @include pux-scale-from("font-size", lg, 7px);
      @include media(lg) {
          margin-left: 2rem;
      }
    }
  }
  &:focus,
  &:hover{
    text-decoration: none;
    background: $btn-default-background;
    color: $base-white;
    .btn-panel-inner {
      &:after {
          color: $base-white;
      }
    }
  }
  &.open {
    z-index: 299;
    .btn-panel-inner {
        &:after {
            transform: rotate(180deg);
        }
    }
  }
}

.dealers-show-more {
    &.open {
        z-index: 299;
        .btn-panel-inner {
            &:after {
                transform: rotate(180deg);
            }
        }
    }
}

.btn-orange {
    background: $color-orange;
    color: $base-white;
    font-size: 13px;
    font-weight: 400;
    font-style: italic;
    @include pux-scale-from("font-size", xl, 15px);
    padding: 1rem 2.3rem;
    letter-spacing: 0.1rem;
    &:focus,
    &:hover{
      text-decoration: none;
      background: darken( $color-orange, 5);
      color: $base-white;
    }
}

.back-btn {
  font-weight: 600;
  font-style: italic;
  font-size: 12px;
  //background-color: #f1eeee;
  font-weight: 600;
  padding: 1rem 3.8rem;
  letter-spacing: 0;
  @include flex-inline;
  @include align-items(center);
  @include media(md) {
    font-size: 14px;
  }
  @include media(xl) {
    font-size: rem(xl, 16px);
  }
  @include media(xxl) {
    font-size: rem(xxl, 18px);
  }
  &:before {
    content: "\e917";
    font-size: 1.2rem;
    margin-right: 1.2rem;
    display: inline-block;
    @include rotate(90deg);
    transition: transform 150ms linear;
    font-weight: 900;
  }
  &:hover,
  &:focus {
    text-decoration: none;
    //background: darken($body-bg, 5);
    color: $base-black;
    &:before {
      transform: rotate(90deg) translate3d(0%, 40%,0);
    }
  }
}

.btn-dark {
    height: 55px;
    color: $base-white;
    padding: 0 20px;
    background-color: rgba($base-black, 0.11);
    border-radius: 28px;
    display: inline-flex;
    align-items: center;
    @include justify-content(center);
    @include pux-scale-from("border-radius", xl, 28px);
    @include media(xl) {
        padding: 0 2.8rem;
    }
    &:hover,
    &:focus {
        color: $base-white;
        background-color: rgba($base-black, 0.4);
    }
}


.image-top-center{
  position: top center !important;
}

.image-top-left{
  position: top left !important;
}

.image-top-right{
  position: top left !important;
}

.image-bottom-center{
  position: bottom center !important;
}

.image-bottom-left{
  position: bottom left !important;
}

.image-bottom-right{
  position: bottom right !important;
}

main{
    min-height:40rem;
    ol{
        margin:0;
        padding:0;
        list-style:none;
        counter-reset: my-ol;
        li{
            position:relative;
            margin:0 0 1.5rem 0;
            padding:0 0 0 2rem;
            &:before{
                content: counter(my-ol)".";
                counter-increment: my-ol;
                color:$ol-number;
                position:absolute;
                left:0;
                top:3px;
                font-weight:bold;
            }
        }
    }
    ul{
        margin:0;
        padding:0;
        list-style:none;
        li{
            margin:0 0 1.5rem 0;
            padding:0 0 0 1.5rem;
            position:relative;
            &:before{
                content:"";
                width:6px;
                height:6px;
                background:$ul-bullet;
                position:absolute;
                top:6px;
                left:0;
                @include border-radius(50%);
            }
        }
    }
    table{
      width:100%;
      tr{
        border-bottom: 1px solid $table-tr-border;
        td{
          padding:1rem 1.5rem;
        }
        &:nth-child(even){
          background: $table-even-background;
        }
      }
    }
    .respo-table{
      table{
        width:100%;
        thead{
          display: none;
          @include media(md){
            display: table-header-group;
          }
          tr{
            border-bottom: 1px solid $table-tr-border;
            th{
              font-weight: bold;
              padding:1rem 1.5rem;
            }
          }
        }
        tbody{
          tr{
            display: block;
            border: 1px solid $table-tr-border;
            border-bottom: none;
            margin:0 0 2rem 0;
            &:nth-child(even){
              background: $table-even-background;
            }
            @include media(md){
              display: table-row;
              margin: 0;
              border: none;
              border-bottom: 1px solid $table-tr-border;
            }
            td{
              @include flex-block;
              @include justify-content(space-between);
              width:100%;
              border-bottom: 1px solid $table-tr-border;
              @include media(md){
                display: table-cell;
                border: none;
                width:auto;
              }
              &:before{
                  content:attr(data-th);
                  display:block;
                  text-align:left;
                  font-family:$font-family !important;
                  margin:0 1.5rem 0 0;
                  @include media(md){
                      display:none;
                  }
              }
            }
          }
        }
      }
    }
}

.small-bullets-list {
  ul{
      li{
          position:relative;
          margin-bottom: 0.2rem;
          &:before{
              content:"";
              width:3px;
              height:3px;
              top:8px;
              @include pux-scale-from("width", xl, 3px);
              @include pux-scale-from("height", xl, 3px);
              @include pux-scale-from("top", xl, 9px);
          }
      }
  }
}

.title-lineheight {
    h1,
    h2,
    h3,
    h4 {
        line-height: 1.4;
    }
}

.h1-border {

}

.shadow-title,
.time-line-title,
.category-title,
.widget-image-right-picture-title,
.category-top-banner-content-title,
.collection-preview,
.category-top-bar-title,
.product-accessories-title,
.base-layout-title,
.search-layout-title {
    h1,
    h2,
    h3 {
        text-shadow: 0 0 136px rgba(0,0,0, 0.23);
    }
}
