@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.products {
  padding-bottom: 10.5rem;
  .pux-container {
    padding-left: 15px;
    padding-right: 15px;
    .row {
      margin: 0 -20px;
      .col-xl-3,
      .col-md-4,
      .col-sm-6,
      .col-12 {
        padding: 0 15px;
      }
    }
  }
}

.product-card {
  position: relative;
  // opacity: 0;
  // top: -5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $base-white;
  box-shadow: 0 8px 65px rgba(0, 0, 0, 0.1);
  height: auto;
  padding: 0 4rem 3rem 4rem;
  margin-top: 8rem;
  margin-bottom: 30px;
  transition: box-shadow ease 300ms;
  // animation: productFadeIn 750ms linear forwards;
  // animation-delay: 500ms;
  &:hover {
   box-shadow: 0 8px 65px rgba(0, 0, 0, 0.2);

   .product-card-body .link {
    &:after {
      left: 15px;
    }
   }
  }

  .product-card-body {
    width: 100%;

    .link {
      color: #e5004c;
      font-style: italic;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0.3px;
      &:after {
        position: relative;
        left: 10px;
        content: '\e902';
        font-size: 11px;
        transition: left ease 300ms;
      }
    }
  }
}

.product-image {
  display: flex;
  justify-content: center;
  align-items: center;
  @include pux-scale-with-min("height", 200px, 150px, 1);
  width: 100%;
  margin-top: -9rem;
  margin-bottom: 2rem;
  img {
    @include pux-scale-with-min("max-height", 200px, 150px, 1);
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.product-name {
  display: flex;
  align-items: center;
  @include pux-scale-with-min("min-height", 46px, 36px);
  @include pux-scale-with-min("font-size", 23px, 18px);
  @include pux-scale-with-min("line-height", 23px, 18px);
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.46px;
  margin-bottom: 5px;
}

// @keyframes productFadeIn {
//   0% {
//     top: -5rem;
//     opacity: 0;
//   }
//   100% {
//     top: 0;
//     opacity: 1;
//   }
// }