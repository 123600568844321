@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.IE11 {
  .widget-image-right {
    @include media(lg) {
      display: block !important;
    }
    &-picture {
      @include media(lg) {
        margin-left: auto;
        margin-right: 0;
        position: relative;
      }
    }
  }
  .widget-image-right-content-image img,
  .widget-image-left-content-image img,
  .widget-image-left-picture {
    width: 100%;
  }
  .scroll-helper {
    @include media(xl) {
      max-height: 100% !important;
    }
  }
  .category-top-banner-content-title {
    @include media(xl) {
      padding-left: 100%;
    }
    .category-banner-title {
      @include media(xl) {
        transform: translateX(-100%) !important;
      }
      &.multi-line,
      &.one-line {
        @include media(xl) {
          display: block !important;
          position: relative;
        }
        .scroll-to-next-title {
          @include media(xl) {
            position: absolute;
            bottom: 0;
            right: 0;
            transform: translate(100%, 0%);
          }
        }
      }
    }
  }
}

.edge,
.browser-edge {
  #map-seznam {
    .map-pin {
      width: auto !important;
    }
  }
}