@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.dashboard-image-right {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include media(xl) {
    padding-top: 12rem;
    @include justify-content(flex-end);
    @include align-items(flex-start);
    @include flex-flow(row);
  }
  @include media(xxl) {
    padding-left: 6.6rem;
    padding-right: 5.6rem;
    padding-top: 10rem;
  }
  &-container {
    max-width: 166rem;
    position: relative;
    margin: 0 auto;
  }
  &-picture {
    flex-shrink: 0;
    position: relative;
    text-align: right;
    img {
      margin-left: auto;
    }
    @include media(xl) {
      text-align: left;
      margin-left: 0;
      max-width: rem(xl, 780px);
      width: 100%;
      img {
        margin-left: 0;
      }
    }
    @include media(xxl) {
      max-width: rem(xxl, 870px);
    }
  }
  &-content {
    background-color: $base-white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;
    padding: 30px 23px 30px 23px;
    @include media(sm) {
      max-width: 76%;
      margin-top: -25rem;
    }
    @include media(xl) {
      max-width: rem(xl, 580px);
      position: absolute;
      top: 6rem;
      left: rem(xl, 70px);
      transform: none;
      padding: 7rem 2rem 7rem 7rem;
      width: 100%;
      margin-top: 0;
    }
    @include media(xxl) {
      max-width: rem(xxl, 747px);
      left: rem(xxl, 55px);
      padding: 8rem 9rem 8rem 8rem;
    }
    &-text {
      @include media(md) {
        max-width: 100%;
      }
      h2 {
        color: $base-black;
        font-size: 28px;
        font-weight: 400;
        line-height: 33px;
        text-transform: uppercase;
        margin-bottom: 3rem;
        strong {
          font-size: 32px;
          font-weight: 800;
        }
      }
      a {
        text-align: left;
      }
    }
    &-image {
      @include flex(0 1 40%);
      max-width: 40%;
      @include align-items(flex-end);
      @include justify-content(center);
      display: none;
      @include media(md) {
        @include flex-block;
      }
      img {
        max-width: 70%;
      }
    }
  }
}

.dashboard-image-left {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 6rem;
  max-width: 172.5rem;
  @include media(sm) {
    @include flex-block;
    @include flex-flow(column);
  }
  @include media(xl) {
    @include justify-content(flex-start);
    @include align-items(flex-start);
    @include flex-flow(row);
    padding-top: 12rem;
  }
  &-container {
    position: relative;
  }
  &-picture {
    position: relative;
    @include media(md) {
      margin-right: 90px;
    }
    @include media(xl) {
      margin-right: 0;
      max-width: rem(xl, 900px);
      width: 100%;
    }
    @include media(xxl) {
      max-width: rem(xxl, 1150px);
    }
  }
  &-content {
    background-color: $base-white;
    @include flex-block;
    @include align-items(flex-end);
    padding: 30px 23px 30px 23px;
    align-self: flex-end;
    width: 100%;
    @include media(sm) {
      margin-top: -25rem;
      max-width: 76%;
    }
    @include media(xl) {
      max-width: rem(xl, 580px);
      position: absolute;
      top: 16rem;
      right: 0;
      transform: none;
      padding: 7rem 7rem 7rem 6rem;
      margin-top: 0;
    }
    @include media(xxl) {
      max-width: rem(xxl, 720px);
      top: 16rem;
      padding: 8rem 9rem 8rem 8rem;
    }
    &-text {
      order: 2;
      @include media(md) {
        width: 100%;
      }
      h2 {
        font-size: 20px;
        color: $base-black;
        font-size: 28px;
        font-weight: 400;
        line-height: 33px;
        text-transform: uppercase;
        margin-bottom: 3rem;
        strong {
          font-size: 32px;
          font-weight: 800;
        }
      }
      a {
        text-align: left;
      }
    }
    &-image {
      @include flex(0 1 40%);
      max-width: 40%;
      @include align-items(flex-end);
      @include justify-content(center);
      display: none;
      @include media(md) {
        @include flex-block;
      }
      img {
        max-width: 70%;
      }
    }
  }
}

.dashboard-btn {
  max-width: 100%;
  white-space: normal;
  padding: 1rem 0; //1rem 2.3rem;
  @include pux-scale-with-min("font-size", 22px, 18px);
  &.mt-base {
    margin-top: 7px;
    @include media(xl) {
      margin-top: rem(xl, 20px);
    }
    @include pux-scale-from("margin-top", xxl, 10px);
  }
}

.dashboard-btn-2 {
  max-width: 100%;
  white-space: normal;
  padding: 1rem 0rem; // 1rem 2rem;
  @include pux-scale-with-min("font-size", 22px, 18px);
  @include media(xl) {
    padding: 1rem 0rem; //1rem 2.3rem;
  }
}

.dashboards-wrapper {
  @include pux-scale-with-min("padding-bottom", 140px, 100px)
}