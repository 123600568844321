@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

$search-icon-width: 12rem; //used for calculating burger size as well - helps with centering logo
$icons-show-breakpoint: 1700px;
$icons-show-breakpoint-125: 1500px;

.pux-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: $container-padding-left;
  padding-right: $container-padding-right;
  max-width: $container-max-width;

  &.wider {
    max-width: 160rem;
  }
  &:after {
    display: block;
    content: "";
    clear: both;
  }
  @include media(md) {
    padding-left: $container-padding-left-md;
    padding-right: $container-padding-right-md;
  }
  &-padding {
    margin-left: auto;
    margin-right: auto;
    max-width: 182rem;
    @include pux-static-size("padding-left", 20px);
    @include pux-static-size("padding-right", 20px);
  }
}

.pux-container {
  .row {
    @include flex-block;
    @include flex-wrap(wrap);
    margin-left: $row-margin-left;
    margin-right: $row-margin-right;
    @include media(md) {
      margin-left: $row-margin-left-md;
      margin-right: $row-margin-right-md;
    }
  }
}

.pux-container {
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-xs-1,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    padding-left: $col-padding-left;
    padding-right: $col-padding-right;
    @include media(md) {
      padding-left: $col-padding-left-md;
      padding-right: $col-padding-right-md;
    }
  }
}

.pux-fluid-container {
  padding-left: $fluid-container-padding-left;
  padding-right: $fluid-container-padding-right;
  @include media(md) {
    padding-left: $fluid-container-padding-left-md;
    padding-right: $fluid-container-padding-right-md;
  }
  @include media(xl) {
    padding-left: $fluid-container-padding-left-xl;
    padding-right: $fluid-container-padding-right-xl;
  }
}

header {
  position: fixed;
  z-index: 22;
  top: 0;
  width: 100%;
  background-color: $base-white;
  box-shadow: 0 0 18px rgba(60, 60, 60, 0.1);
  transition: top ease 300ms;
  &.scrolled.up {
    @include media($menu-break) {
      top: 0;
    }
  }
  &.scrolled.down {
    @include media($menu-break) {
      top: -94px;
    }
  }
  .header-gradient {
    background: linear-gradient(to left, #31011d 0%, #88004a 35%, #e3004a 100%);
    @include pux-scale-with-min("height", 10px, 7.5px);
  }
  .header {
    @include pux-scale-with-min("height", 84px, 63px);
    @include flex-block;
    @include align-items(center);
    @include justify-content(space-between);
    &-logo {
      a {
        display: inline-block;
        span {
          display: none;
        }
        img {
          max-width: none;
          @include pux-static-size("height", 25px, 1);
        }
      }
    }
    &-menu {
      display: none;
      position: absolute;
      top: 100%;
      z-index: 77;
      left: 0;
      background: linear-gradient(
        to left,
        #31011d 0%,
        #88004a 35%,
        #e3004a 100%
      );
      width: 100%;
      @include calc("max-height", "100vh - 5rem");
      @include media($menu-break) {
        @include flex-block;
        position: static;
        background: none;
        width: auto;
      }
      ul {
        margin: 0;
        padding: 15px 0;
        list-style: none;
        @include media($menu-break) {
          @include flex-block;
          padding: 0;
        }
        li {
          display: block;
          position: relative;
          @include media($menu-break) {
            margin: 0;
            @include flex-block;
            @include align-items(center);
          }
          a {
            display: block;
            padding: 15px 10px;
            color: $base-white;
            font-size: 17px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 0.34px;
            transition: color ease 300ms, margin-left ease 300ms;
            @include media($menu-break) {
              color: $base-black;
              font-size: 15px;
              padding: 1.5rem 1.75rem;
            }
            @include media(lg) {
              font-size: 17px;
            }
            @include media(xl) {
              padding: 1.5rem 3.75rem;
            }
          }
          &:last-child {
            a {
              @include media($menu-break) {
                padding: 1.5rem 0 1.5rem 1.75rem;
              }
              @include media(xl) {
                padding: 1.5rem 0 1.5rem 3.75rem;
              }
            }
          }
          &.Highlighted {
            a {
              color: $base-white;
              margin-left: 20px;
              @include media($menu-break) {
                color: $main-color;
                margin-left: 0;
              }
            }
          }
          ul {
            display: none;
            width: 100%;
            @include opacity(1);
            visibility: visible;
            @include transition;
            @include media($menu-break) {
              position: absolute;
              width: 20rem;
              visibility: hidden;
              @include opacity(0);
              display: block;
              top: 100%;
            }
            li {
              a {
                &:focus,
                &:hover {
                }
              }
            }
          }
          &.hovered,
          &:hover {
            & > a {
              color: $base-white;
              margin-left: 20px;
              @include media($menu-break) {
                color: $main-color;
                margin-left: 0;
              }
            }
            & > ul {
              visibility: visible;
              @include opacity(1);
            }
          }
          &.open {
            .menu-openner {
              &:after {
                @include rotate(0deg);
              }
            }
            ul {
              display: block;
            }
          }
        }
      }
      .menu-openner {
        position: absolute;
        top: 0;
        right: 0;
        background: $main-color;
        color: #fff;
        width: 6rem;
        height: 6rem;
        @include media($menu-break) {
          display: none;
        }
        &:after,
        &:before {
          content: "";
          width: 70%;
          height: 2px;
          background: #fff;
          position: absolute;
          left: 15%;
          top: 50%;
          margin-top: -1px;
        }
        &:after {
          @include rotate(90deg);
          @include transition;
        }
      }
    }
    &-hamburger {
      @include media($menu-break) {
        display: none;
      }
      .open-menu {
        width: $hamburger-width;
        height: $hamburger-width;
        position: relative;
        display: block;
        span {
          width: $hamburger-width - 1rem;
          position: absolute;
          left: 0.5rem;
          height: 2px;
          background: $main-color;
          margin-top: -1px;
          top: 50%;
          @include transition;
          &:before,
          &:after {
            content: "";
            width: $hamburger-width - 1rem;
            left: 0;
            height: 2px;
            background: $main-color;
            position: absolute;
            @include transition;
          }
          &:before {
            top: -1rem;
          }
          &:after {
            bottom: -1rem;
          }
        }
      }
    }
    &.open {
      .header-hamburger {
        .open-menu {
          span {
            background: transparent;
            &:before {
              top: 0;
              @include rotate(45deg);
            }
            &:after {
              top: 0;
              @include rotate(-45deg);
            }
          }
        }
      }
      .header-menu {
        display: block;
      }
    }
  }
}

main {
  @include pux-scale-with-min("padding-top", 94px, 70.5px);
}

.EditMode,
.DesignMode {
  header {
    position: static;
  }
}

footer {
  background-color: $color-dark-gray;
  color: $base-white;
  padding: 5rem 0;
  .footer {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    @include media(md) {
      flex-direction: row;
    }
    .btn-up {
      position: absolute;
      z-index: 1;
      top: -10rem;
      right: 0;
      width: 75px;
      height: 75px;
      box-shadow: 0 8px 65px rgba(0, 0, 0, 0.1);
      background-color: $base-white;
      transition: background-color ease 300ms;
      &:before {
        position: absolute;
        top: 24px;
        left: 24px;
        content: "\e900";
        color: $main-color;
        font-size: 27px;
        transform: rotate(180deg);
        transform-origin: center;
        transition: color ease 300ms;
      }
      &:hover,
      &:focus {
        cursor: pointer;
        background-color: $main-color;
        &:before {
          color: $base-white;
        }
      }
    }
  }
  .footer-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 5rem 0 2rem 0;
    @include media(md) {
      margin: 0 0 2rem 0;
    }
    @include media(xl) {
      margin: 0 5rem 2rem 0;
    }
    img {
      margin-top: 10px;
      @include pux-static-size("height", 30px, 1);
    }
    .footer-copy {
      font-size: 13px;
      font-weight: 300;
      margin-top: 4rem;
    }
  }
  .footer-menu {
    ul {
      list-style: none;
      margin: 0 0 2rem -8rem;
      padding: 0;
      @include media(sm) {
        columns: 2;
      }
      @include media(md) {
        margin: 0 0 2rem 0;
      }
      li {
        margin: 0 0 0 8rem;
        @include media(lg) {
          margin: 0 0 0 12rem;
        }
        padding: 0;
        &:before {
          content: none;
          display: none;
        }
        a {
          color: $base-white;
          font-size: 14px;
          font-weight: 300;
          letter-spacing: 0.28px;
          transition: color ease 300ms;
          &:hover,
          &:focus {
            color: $main-color;
          }
        }
      }
    }
  }
}

.is-xxl {
  display: none;
  @include media(xxl) {
    display: block;
  }
}

.is-lg {
  display: none;
  @include media(lg) {
    display: block;
  }
}

.is-md {
  display: none;
  @include media(md) {
    display: block;
  }
}

body {
  background: $body-bg;
}

.hidden-lg-down {
  display: none;

  @include media(xl) {
    display: block;
  }
}

.hidden-sm-down {
  display: none;

  @include media(md) {
    display: block;
  }
}

.base-layout {
  margin-top: 30px;
  @include media(md) {
    margin-top: 30px;
  }
  @include media(lg) {
    margin-top: rem(lg, 30px);
  }
  @include media(xl) {
    margin-top: rem(xl, 40px);
  }
  @include media(xxl) {
    margin-top: rem(xxl, 70px);
  }
  &-title {
    position: relative;
    padding-left: 8%;
    @include media(md) {
      padding-left: 8%;
    }
    @include media(xl) {
      z-index: -1;
      padding-left: 5%;
    }
    @include media(xxl) {
      padding-left: 8.5%;
    }
  }
  &-content {
    margin-left: 14%;
    @include media(md) {
      margin-left: 23%;
    }
    @include media(lg) {
      margin-left: 20%;
    }
    @include media(xxl) {
      margin-left: calc((100vw - 117rem) / 2);
    }
    @media (min-width: 2000px) {
      margin-left: 38rem;
    }
  }
  .show-more-container {
    padding: 10px 0;
    text-align: center;
  }
}

.bg-white {
  background-color: $base-white;
}

.search-layout {
  margin-top: 30px;
  @include media(md) {
    margin-top: 30px;
  }
  @include media(lg) {
    margin-top: rem(lg, 30px);
  }
  @include media(xl) {
    margin-top: rem(xl, 40px);
  }
  @include media(xxl) {
    margin-top: rem(xxl, 70px);
  }
  &-title {
    position: relative;
    padding-left: 6%;
    @include media(md) {
      padding-left: 6%;
      padding-bottom: 20px;
    }
    @include media(xl) {
      z-index: -1;
      padding-left: 5%;
      padding-bottom: 0;
    }
    @include media(xxl) {
      padding-left: 8.5%;
    }
  }
  &-content {
    margin-left: 10%;
    margin-right: 10%;
    @include media(lg) {
      margin-left: 20%;
      margin-left: 20%;
    }
    @include media(xl) {
      margin-right: 0;
    }
    @include media(xxl) {
      margin-left: calc((100vw - 117rem) / 2);
    }

    @media (min-width: 2000px) {
      margin-left: 38rem;
    }
  }
}

.overflow-hidden {
  overflow: hidden;
}

.absolute-container {
  position: absolute;
  z-index: 2;
}

.banner-404 {
  margin-bottom: 5rem;

  h1 {
    margin-bottom: 0;
    margin-top: 5rem;
  }

  .category-top-banner-image-inner {
    animation: none;
  }

  .widget-video-background {
    height: 82%;
  }

  .category-top-banner-content-perex {
    margin-top: 5rem;

    .btn {
      margin-top: 3.5rem;
      white-space: normal;

      @include media(xxl) {
        white-space: nowrap;
      }
    }
  }

  .widget-video-background .video {
    top: 2.5rem;

    @include media(md) {
      padding-right: 10rem;
    }

    @include media(xl) {
      padding-right: 0;
    }
  }

  .category-top-banner-content-perex {
    @include media(xxl) {
      padding-left: 3rem;
    }
  }
}

.page404-back-btn-wrap {
  padding: 20px 30px 0 30px;
  margin-bottom: -2rem;

  @include media(md) {
    padding: 30px 30px 0 60px;
  }

  @include media(xl) {
    display: none;
  }

  .btn {
    background: #efefef;

    &:hover,
    &:focus {
      background: darken(#efefef, 7%);
      color: $base-black;
    }
  }
}

.layout-2-1 {
  @include flex-block;
  @include flex-flow(column);
  @include media(md) {
    margin: 100px 30px 50px;
  }
  @include media(xl) {
    margin: 0;
    margin-top: 4.2rem;
    @include flex-flow(row);
  }
  &-main {
    @include media(xl) {
      flex: 1;
      padding-left: rem(xl, 40px);
    }
    @include media(xxl) {
      padding-left: 14rem;
    }
  }
  &-side {
    padding: 40px 30px;
    text-shadow: 0 0 70px rgba(0, 0, 0, 0.23);
    @include media(md) {
      padding: 55px 40px;
    }
    @include media(xl) {
      padding: 0;
      padding-left: 6rem;
      flex: 0 0 rem(xl, 370px);
      max-width: rem(xl, 370px);
      padding: 0 rem(xl, 30px);
    }
    @include media(xxl) {
      flex: 0 0 31%;
      max-width: 31%;
      padding: 0 6.5rem;
    }
  }
}

.active-products {
  background-color: $base-white;
  &-inner {
    width: 90%;
    max-width: 120rem;
    position: relative;
    margin: 0 auto;
    padding: 5rem 0;
    table {
      th {
        padding: 1rem 1.5rem;
      }
    }
  }
}

.space-big {
  .row {
    margin: 0 -4rem;
    [class*="col-"] {
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }
}

.menu-overplace {
  display: none;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100vw;
    height: 50vh;
    background-color: transparent;
    transform: translateY(100%);
    z-index: -2;
  }
  &:hover,
  &:focus {
    animation: menu-hover 0ms forwards;
    animation-delay: 300ms;
  }
}

.mobile-hide {
  display: none !important;
  @include media(md) {
    display: block !important;
  }
}

//* __Lazy Load__ */
@keyframes animateLoaded__ {
  0% {
    opacity: 0;
    filter: blur(10px);
  }
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

[data-lazy-type] {
  &.loaded {
    animation: $imgLoadAnimation;
  }
}
