.pux-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 156rem; }
  .pux-container.wider {
    max-width: 160rem; }
  .pux-container:after {
    display: block;
    content: "";
    clear: both; }
  @media (min-width: 768px) {
    .pux-container {
      padding-left: 10px;
      padding-right: 10px; } }
  .pux-container-padding {
    margin-left: auto;
    margin-right: auto;
    max-width: 182rem;
    padding-left: 4rem;
    padding-right: 4rem; }
    @media (min-width: 480px) {
      .pux-container-padding {
        padding-left: 3.63636rem; } }
    @media (min-width: 545px) {
      .pux-container-padding {
        padding-left: 3.33333rem; } }
    @media (min-width: 768px) {
      .pux-container-padding {
        padding-left: 2.66667rem; } }
    @media (min-width: 992px) {
      .pux-container-padding {
        padding-left: 2.5rem; } }
    @media (min-width: 1200px) {
      .pux-container-padding {
        padding-left: 2.22222rem; } }
    @media (min-width: 1400px) {
      .pux-container-padding {
        padding-left: 2rem; } }
    @media (min-width: 480px) {
      .pux-container-padding {
        padding-right: 3.63636rem; } }
    @media (min-width: 545px) {
      .pux-container-padding {
        padding-right: 3.33333rem; } }
    @media (min-width: 768px) {
      .pux-container-padding {
        padding-right: 2.66667rem; } }
    @media (min-width: 992px) {
      .pux-container-padding {
        padding-right: 2.5rem; } }
    @media (min-width: 1200px) {
      .pux-container-padding {
        padding-right: 2.22222rem; } }
    @media (min-width: 1400px) {
      .pux-container-padding {
        padding-right: 2rem; } }

.pux-container .row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px; }
  @media (min-width: 768px) {
    .pux-container .row {
      margin-left: -10px;
      margin-right: -10px; } }

.pux-container .col-1,
.pux-container .col-2,
.pux-container .col-3,
.pux-container .col-4,
.pux-container .col-5,
.pux-container .col-6,
.pux-container .col-7,
.pux-container .col-8,
.pux-container .col-9,
.pux-container .col-10,
.pux-container .col-11,
.pux-container .col-12,
.pux-container .col,
.pux-container .col-xs-1,
.pux-container .col-xs-2,
.pux-container .col-xs-3,
.pux-container .col-xs-4,
.pux-container .col-xs-5,
.pux-container .col-xs-6,
.pux-container .col-xs-7,
.pux-container .col-xs-8,
.pux-container .col-xs-9,
.pux-container .col-xs-10,
.pux-container .col-xs-11,
.pux-container .col-xs-12,
.pux-container .col-auto,
.pux-container .col-sm-1,
.pux-container .col-sm-2,
.pux-container .col-sm-3,
.pux-container .col-sm-4,
.pux-container .col-sm-5,
.pux-container .col-sm-6,
.pux-container .col-sm-7,
.pux-container .col-sm-8,
.pux-container .col-sm-9,
.pux-container .col-sm-10,
.pux-container .col-sm-11,
.pux-container .col-sm-12,
.pux-container .col-sm,
.pux-container .col-sm-auto,
.pux-container .col-md-1,
.pux-container .col-md-2,
.pux-container .col-md-3,
.pux-container .col-md-4,
.pux-container .col-md-5,
.pux-container .col-md-6,
.pux-container .col-md-7,
.pux-container .col-md-8,
.pux-container .col-md-9,
.pux-container .col-md-10,
.pux-container .col-md-11,
.pux-container .col-md-12,
.pux-container .col-md,
.pux-container .col-md-auto,
.pux-container .col-lg-1,
.pux-container .col-lg-2,
.pux-container .col-lg-3,
.pux-container .col-lg-4,
.pux-container .col-lg-5,
.pux-container .col-lg-6,
.pux-container .col-lg-7,
.pux-container .col-lg-8,
.pux-container .col-lg-9,
.pux-container .col-lg-10,
.pux-container .col-lg-11,
.pux-container .col-lg-12,
.pux-container .col-lg,
.pux-container .col-lg-auto,
.pux-container .col-xl-1,
.pux-container .col-xl-2,
.pux-container .col-xl-3,
.pux-container .col-xl-4,
.pux-container .col-xl-5,
.pux-container .col-xl-6,
.pux-container .col-xl-7,
.pux-container .col-xl-8,
.pux-container .col-xl-9,
.pux-container .col-xl-10,
.pux-container .col-xl-11,
.pux-container .col-xl-12,
.pux-container .col-xl,
.pux-container .col-xl-auto {
  padding-left: 10px;
  padding-right: 10px; }
  @media (min-width: 768px) {
    .pux-container .col-1,
    .pux-container .col-2,
    .pux-container .col-3,
    .pux-container .col-4,
    .pux-container .col-5,
    .pux-container .col-6,
    .pux-container .col-7,
    .pux-container .col-8,
    .pux-container .col-9,
    .pux-container .col-10,
    .pux-container .col-11,
    .pux-container .col-12,
    .pux-container .col,
    .pux-container .col-xs-1,
    .pux-container .col-xs-2,
    .pux-container .col-xs-3,
    .pux-container .col-xs-4,
    .pux-container .col-xs-5,
    .pux-container .col-xs-6,
    .pux-container .col-xs-7,
    .pux-container .col-xs-8,
    .pux-container .col-xs-9,
    .pux-container .col-xs-10,
    .pux-container .col-xs-11,
    .pux-container .col-xs-12,
    .pux-container .col-auto,
    .pux-container .col-sm-1,
    .pux-container .col-sm-2,
    .pux-container .col-sm-3,
    .pux-container .col-sm-4,
    .pux-container .col-sm-5,
    .pux-container .col-sm-6,
    .pux-container .col-sm-7,
    .pux-container .col-sm-8,
    .pux-container .col-sm-9,
    .pux-container .col-sm-10,
    .pux-container .col-sm-11,
    .pux-container .col-sm-12,
    .pux-container .col-sm,
    .pux-container .col-sm-auto,
    .pux-container .col-md-1,
    .pux-container .col-md-2,
    .pux-container .col-md-3,
    .pux-container .col-md-4,
    .pux-container .col-md-5,
    .pux-container .col-md-6,
    .pux-container .col-md-7,
    .pux-container .col-md-8,
    .pux-container .col-md-9,
    .pux-container .col-md-10,
    .pux-container .col-md-11,
    .pux-container .col-md-12,
    .pux-container .col-md,
    .pux-container .col-md-auto,
    .pux-container .col-lg-1,
    .pux-container .col-lg-2,
    .pux-container .col-lg-3,
    .pux-container .col-lg-4,
    .pux-container .col-lg-5,
    .pux-container .col-lg-6,
    .pux-container .col-lg-7,
    .pux-container .col-lg-8,
    .pux-container .col-lg-9,
    .pux-container .col-lg-10,
    .pux-container .col-lg-11,
    .pux-container .col-lg-12,
    .pux-container .col-lg,
    .pux-container .col-lg-auto,
    .pux-container .col-xl-1,
    .pux-container .col-xl-2,
    .pux-container .col-xl-3,
    .pux-container .col-xl-4,
    .pux-container .col-xl-5,
    .pux-container .col-xl-6,
    .pux-container .col-xl-7,
    .pux-container .col-xl-8,
    .pux-container .col-xl-9,
    .pux-container .col-xl-10,
    .pux-container .col-xl-11,
    .pux-container .col-xl-12,
    .pux-container .col-xl,
    .pux-container .col-xl-auto {
      padding-left: 10px;
      padding-right: 10px; } }

.pux-fluid-container {
  padding-left: 10px;
  padding-right: 10px; }
  @media (min-width: 768px) {
    .pux-fluid-container {
      padding-left: 10px;
      padding-right: 10px; } }
  @media (min-width: 1200px) {
    .pux-fluid-container {
      padding-left: 0;
      padding-right: 0; } }

header {
  position: fixed;
  z-index: 22;
  top: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 18px rgba(60, 60, 60, 0.1);
  transition: top ease 300ms; }
  @media (min-width: 768px) {
    header.scrolled.up {
      top: 0; } }
  @media (min-width: 768px) {
    header.scrolled.down {
      top: -94px; } }
  header .header-gradient {
    background: linear-gradient(to left, #31011d 0%, #88004a 35%, #e3004a 100%); }
    @media (min-width: 320px) {
      header .header-gradient {
        height: 1.5rem; } }
    @media (min-width: 480px) {
      header .header-gradient {
        height: 1.36364rem; } }
    @media (min-width: 545px) {
      header .header-gradient {
        height: 1.25rem; } }
    @media (min-width: 768px) {
      header .header-gradient {
        height: 1rem; } }
  header .header {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between; }
    @media (min-width: 320px) {
      header .header {
        height: 12.6rem; } }
    @media (min-width: 480px) {
      header .header {
        height: 11.45455rem; } }
    @media (min-width: 545px) {
      header .header {
        height: 10.5rem; } }
    @media (min-width: 768px) {
      header .header {
        height: 8.4rem; } }
    header .header-logo a {
      display: inline-block; }
      header .header-logo a span {
        display: none; }
      header .header-logo a img {
        max-width: none;
        height: 5rem 0.2rem; }
        @media (min-width: 480px) {
          header .header-logo a img {
            height: 4.54545rem 0.18182rem; } }
        @media (min-width: 545px) {
          header .header-logo a img {
            height: 4.16667rem 0.16667rem; } }
        @media (min-width: 768px) {
          header .header-logo a img {
            height: 3.33333rem 0.13333rem; } }
        @media (min-width: 992px) {
          header .header-logo a img {
            height: 3.125rem 0.125rem; } }
        @media (min-width: 1200px) {
          header .header-logo a img {
            height: 2.77778rem 0.11111rem; } }
        @media (min-width: 1400px) {
          header .header-logo a img {
            height: 2.5rem 0.1rem; } }
    header .header-menu {
      display: none;
      position: absolute;
      top: 100%;
      z-index: 77;
      left: 0;
      background: linear-gradient(to left, #31011d 0%, #88004a 35%, #e3004a 100%);
      width: 100%;
      max-height: -webkit-calc(100vh - 5rem);
      max-height: -moz-calc(100vh - 5rem);
      max-height: calc(100vh - 5rem); }
      @media (min-width: 768px) {
        header .header-menu {
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          position: static;
          background: none;
          width: auto; } }
      header .header-menu ul {
        margin: 0;
        padding: 15px 0;
        list-style: none; }
        @media (min-width: 768px) {
          header .header-menu ul {
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            padding: 0; } }
        header .header-menu ul li {
          display: block;
          position: relative; }
          @media (min-width: 768px) {
            header .header-menu ul li {
              margin: 0;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;
              -webkit-box-align: center;
              -moz-box-align: center;
              -ms-flex-align: center;
              -webkit-align-items: center;
              align-items: center; } }
          header .header-menu ul li a {
            display: block;
            padding: 15px 10px;
            color: #fff;
            font-size: 17px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 0.34px;
            transition: color ease 300ms, margin-left ease 300ms; }
            @media (min-width: 768px) {
              header .header-menu ul li a {
                color: #000001;
                font-size: 15px;
                padding: 1.5rem 1.75rem; } }
            @media (min-width: 992px) {
              header .header-menu ul li a {
                font-size: 17px; } }
            @media (min-width: 1200px) {
              header .header-menu ul li a {
                padding: 1.5rem 3.75rem; } }
          @media (min-width: 768px) {
            header .header-menu ul li:last-child a {
              padding: 1.5rem 0 1.5rem 1.75rem; } }
          @media (min-width: 1200px) {
            header .header-menu ul li:last-child a {
              padding: 1.5rem 0 1.5rem 3.75rem; } }
          header .header-menu ul li.Highlighted a {
            color: #fff;
            margin-left: 20px; }
            @media (min-width: 768px) {
              header .header-menu ul li.Highlighted a {
                color: #e5004c;
                margin-left: 0; } }
          header .header-menu ul li ul {
            display: none;
            width: 100%;
            opacity: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            filter: alpha(opacity=100);
            visibility: visible;
            -moz-transition: all ease-in-out 200ms;
            -o-transition: all ease-in-out 200ms;
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms; }
            @media (min-width: 768px) {
              header .header-menu ul li ul {
                position: absolute;
                width: 20rem;
                visibility: hidden;
                opacity: 0;
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
                filter: alpha(opacity=0);
                display: block;
                top: 100%; } }
          header .header-menu ul li.hovered > a, header .header-menu ul li:hover > a {
            color: #fff;
            margin-left: 20px; }
            @media (min-width: 768px) {
              header .header-menu ul li.hovered > a, header .header-menu ul li:hover > a {
                color: #e5004c;
                margin-left: 0; } }
          header .header-menu ul li.hovered > ul, header .header-menu ul li:hover > ul {
            visibility: visible;
            opacity: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            filter: alpha(opacity=100); }
          header .header-menu ul li.open .menu-openner:after {
            -ms-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
          header .header-menu ul li.open ul {
            display: block; }
      header .header-menu .menu-openner {
        position: absolute;
        top: 0;
        right: 0;
        background: #e5004c;
        color: #fff;
        width: 6rem;
        height: 6rem; }
        @media (min-width: 768px) {
          header .header-menu .menu-openner {
            display: none; } }
        header .header-menu .menu-openner:after, header .header-menu .menu-openner:before {
          content: "";
          width: 70%;
          height: 2px;
          background: #fff;
          position: absolute;
          left: 15%;
          top: 50%;
          margin-top: -1px; }
        header .header-menu .menu-openner:after {
          -ms-transform: rotate(90deg);
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
    @media (min-width: 768px) {
      header .header-hamburger {
        display: none; } }
    header .header-hamburger .open-menu {
      width: 6rem;
      height: 6rem;
      position: relative;
      display: block; }
      header .header-hamburger .open-menu span {
        width: 5rem;
        position: absolute;
        left: 0.5rem;
        height: 2px;
        background: #e5004c;
        margin-top: -1px;
        top: 50%;
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms; }
        header .header-hamburger .open-menu span:before, header .header-hamburger .open-menu span:after {
          content: "";
          width: 5rem;
          left: 0;
          height: 2px;
          background: #e5004c;
          position: absolute;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
        header .header-hamburger .open-menu span:before {
          top: -1rem; }
        header .header-hamburger .open-menu span:after {
          bottom: -1rem; }
    header .header.open .header-hamburger .open-menu span {
      background: transparent; }
      header .header.open .header-hamburger .open-menu span:before {
        top: 0;
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg); }
      header .header.open .header-hamburger .open-menu span:after {
        top: 0;
        -ms-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg); }
    header .header.open .header-menu {
      display: block; }

@media (min-width: 320px) {
  main {
    padding-top: 14.1rem; } }

@media (min-width: 480px) {
  main {
    padding-top: 12.81818rem; } }

@media (min-width: 545px) {
  main {
    padding-top: 11.75rem; } }

@media (min-width: 768px) {
  main {
    padding-top: 9.4rem; } }

.EditMode header,
.DesignMode header {
  position: static; }

footer {
  background-color: #1f1f1f;
  color: #fff;
  padding: 5rem 0; }
  footer .footer {
    position: relative;
    display: flex;
    flex-direction: column-reverse; }
    @media (min-width: 768px) {
      footer .footer {
        flex-direction: row; } }
    footer .footer .btn-up {
      position: absolute;
      z-index: 1;
      top: -10rem;
      right: 0;
      width: 75px;
      height: 75px;
      box-shadow: 0 8px 65px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      transition: background-color ease 300ms; }
      footer .footer .btn-up:before {
        position: absolute;
        top: 24px;
        left: 24px;
        content: "\e900";
        color: #e5004c;
        font-size: 27px;
        transform: rotate(180deg);
        transform-origin: center;
        transition: color ease 300ms; }
      footer .footer .btn-up:hover, footer .footer .btn-up:focus {
        cursor: pointer;
        background-color: #e5004c; }
        footer .footer .btn-up:hover:before, footer .footer .btn-up:focus:before {
          color: #fff; }
  footer .footer-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 5rem 0 2rem 0; }
    @media (min-width: 768px) {
      footer .footer-info {
        margin: 0 0 2rem 0; } }
    @media (min-width: 1200px) {
      footer .footer-info {
        margin: 0 5rem 2rem 0; } }
    footer .footer-info img {
      margin-top: 10px;
      height: 6rem 0.2rem; }
      @media (min-width: 480px) {
        footer .footer-info img {
          height: 5.45455rem 0.18182rem; } }
      @media (min-width: 545px) {
        footer .footer-info img {
          height: 5rem 0.16667rem; } }
      @media (min-width: 768px) {
        footer .footer-info img {
          height: 4rem 0.13333rem; } }
      @media (min-width: 992px) {
        footer .footer-info img {
          height: 3.75rem 0.125rem; } }
      @media (min-width: 1200px) {
        footer .footer-info img {
          height: 3.33333rem 0.11111rem; } }
      @media (min-width: 1400px) {
        footer .footer-info img {
          height: 3rem 0.1rem; } }
    footer .footer-info .footer-copy {
      font-size: 13px;
      font-weight: 300;
      margin-top: 4rem; }
  footer .footer-menu ul {
    list-style: none;
    margin: 0 0 2rem -8rem;
    padding: 0; }
    @media (min-width: 545px) {
      footer .footer-menu ul {
        columns: 2; } }
    @media (min-width: 768px) {
      footer .footer-menu ul {
        margin: 0 0 2rem 0; } }
    footer .footer-menu ul li {
      margin: 0 0 0 8rem;
      padding: 0; }
      @media (min-width: 992px) {
        footer .footer-menu ul li {
          margin: 0 0 0 12rem; } }
      footer .footer-menu ul li:before {
        content: none;
        display: none; }
      footer .footer-menu ul li a {
        color: #fff;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0.28px;
        transition: color ease 300ms; }
        footer .footer-menu ul li a:hover, footer .footer-menu ul li a:focus {
          color: #e5004c; }

.is-xxl {
  display: none; }
  @media (min-width: 1400px) {
    .is-xxl {
      display: block; } }

.is-lg {
  display: none; }
  @media (min-width: 992px) {
    .is-lg {
      display: block; } }

.is-md {
  display: none; }
  @media (min-width: 768px) {
    .is-md {
      display: block; } }

body {
  background: #dfdfdf; }

.hidden-lg-down {
  display: none; }
  @media (min-width: 1200px) {
    .hidden-lg-down {
      display: block; } }

.hidden-sm-down {
  display: none; }
  @media (min-width: 768px) {
    .hidden-sm-down {
      display: block; } }

.base-layout {
  margin-top: 30px; }
  @media (min-width: 768px) {
    .base-layout {
      margin-top: 30px; } }
  @media (min-width: 992px) {
    .base-layout {
      margin-top: 3.75rem; } }
  @media (min-width: 1200px) {
    .base-layout {
      margin-top: 4.44444rem; } }
  @media (min-width: 1400px) {
    .base-layout {
      margin-top: 7rem; } }
  .base-layout-title {
    position: relative;
    padding-left: 8%; }
    @media (min-width: 768px) {
      .base-layout-title {
        padding-left: 8%; } }
    @media (min-width: 1200px) {
      .base-layout-title {
        z-index: -1;
        padding-left: 5%; } }
    @media (min-width: 1400px) {
      .base-layout-title {
        padding-left: 8.5%; } }
  .base-layout-content {
    margin-left: 14%; }
    @media (min-width: 768px) {
      .base-layout-content {
        margin-left: 23%; } }
    @media (min-width: 992px) {
      .base-layout-content {
        margin-left: 20%; } }
    @media (min-width: 1400px) {
      .base-layout-content {
        margin-left: calc((100vw - 117rem) / 2); } }
    @media (min-width: 2000px) {
      .base-layout-content {
        margin-left: 38rem; } }
  .base-layout .show-more-container {
    padding: 10px 0;
    text-align: center; }

.bg-white {
  background-color: #fff; }

.search-layout {
  margin-top: 30px; }
  @media (min-width: 768px) {
    .search-layout {
      margin-top: 30px; } }
  @media (min-width: 992px) {
    .search-layout {
      margin-top: 3.75rem; } }
  @media (min-width: 1200px) {
    .search-layout {
      margin-top: 4.44444rem; } }
  @media (min-width: 1400px) {
    .search-layout {
      margin-top: 7rem; } }
  .search-layout-title {
    position: relative;
    padding-left: 6%; }
    @media (min-width: 768px) {
      .search-layout-title {
        padding-left: 6%;
        padding-bottom: 20px; } }
    @media (min-width: 1200px) {
      .search-layout-title {
        z-index: -1;
        padding-left: 5%;
        padding-bottom: 0; } }
    @media (min-width: 1400px) {
      .search-layout-title {
        padding-left: 8.5%; } }
  .search-layout-content {
    margin-left: 10%;
    margin-right: 10%; }
    @media (min-width: 992px) {
      .search-layout-content {
        margin-left: 20%;
        margin-left: 20%; } }
    @media (min-width: 1200px) {
      .search-layout-content {
        margin-right: 0; } }
    @media (min-width: 1400px) {
      .search-layout-content {
        margin-left: calc((100vw - 117rem) / 2); } }
    @media (min-width: 2000px) {
      .search-layout-content {
        margin-left: 38rem; } }

.overflow-hidden {
  overflow: hidden; }

.absolute-container {
  position: absolute;
  z-index: 2; }

.banner-404 {
  margin-bottom: 5rem; }
  .banner-404 h1 {
    margin-bottom: 0;
    margin-top: 5rem; }
  .banner-404 .category-top-banner-image-inner {
    animation: none; }
  .banner-404 .widget-video-background {
    height: 82%; }
  .banner-404 .category-top-banner-content-perex {
    margin-top: 5rem; }
    .banner-404 .category-top-banner-content-perex .btn {
      margin-top: 3.5rem;
      white-space: normal; }
      @media (min-width: 1400px) {
        .banner-404 .category-top-banner-content-perex .btn {
          white-space: nowrap; } }
  .banner-404 .widget-video-background .video {
    top: 2.5rem; }
    @media (min-width: 768px) {
      .banner-404 .widget-video-background .video {
        padding-right: 10rem; } }
    @media (min-width: 1200px) {
      .banner-404 .widget-video-background .video {
        padding-right: 0; } }
  @media (min-width: 1400px) {
    .banner-404 .category-top-banner-content-perex {
      padding-left: 3rem; } }

.page404-back-btn-wrap {
  padding: 20px 30px 0 30px;
  margin-bottom: -2rem; }
  @media (min-width: 768px) {
    .page404-back-btn-wrap {
      padding: 30px 30px 0 60px; } }
  @media (min-width: 1200px) {
    .page404-back-btn-wrap {
      display: none; } }
  .page404-back-btn-wrap .btn {
    background: #efefef; }
    .page404-back-btn-wrap .btn:hover, .page404-back-btn-wrap .btn:focus {
      background: #dddddd;
      color: #000001; }

.layout-2-1 {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-flow: column nowrap;
  -ms-flex-direction: column;
  -ms-flex-wrap: nowrap;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap; }
  @media (min-width: 768px) {
    .layout-2-1 {
      margin: 100px 30px 50px; } }
  @media (min-width: 1200px) {
    .layout-2-1 {
      margin: 0;
      margin-top: 4.2rem;
      -moz-flex-flow: row nowrap;
      -ms-flex-direction: row;
      -ms-flex-wrap: nowrap;
      -webkit-flex-flow: row nowrap;
      flex-flow: row nowrap; } }
  @media (min-width: 1200px) {
    .layout-2-1-main {
      flex: 1;
      padding-left: 4.44444rem; } }
  @media (min-width: 1400px) {
    .layout-2-1-main {
      padding-left: 14rem; } }
  .layout-2-1-side {
    padding: 40px 30px;
    text-shadow: 0 0 70px rgba(0, 0, 0, 0.23); }
    @media (min-width: 768px) {
      .layout-2-1-side {
        padding: 55px 40px; } }
    @media (min-width: 1200px) {
      .layout-2-1-side {
        padding: 0;
        padding-left: 6rem;
        flex: 0 0 41.11111rem;
        max-width: 41.11111rem;
        padding: 0 3.33333rem; } }
    @media (min-width: 1400px) {
      .layout-2-1-side {
        flex: 0 0 31%;
        max-width: 31%;
        padding: 0 6.5rem; } }

.active-products {
  background-color: #fff; }
  .active-products-inner {
    width: 90%;
    max-width: 120rem;
    position: relative;
    margin: 0 auto;
    padding: 5rem 0; }
    .active-products-inner table th {
      padding: 1rem 1.5rem; }

.space-big .row {
  margin: 0 -4rem; }
  .space-big .row [class*="col-"] {
    padding-left: 4rem;
    padding-right: 4rem; }

.menu-overplace {
  display: none; }
  .menu-overplace:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100vw;
    height: 50vh;
    background-color: transparent;
    transform: translateY(100%);
    z-index: -2; }
  .menu-overplace:hover, .menu-overplace:focus {
    animation: menu-hover 0ms forwards;
    animation-delay: 300ms; }

.mobile-hide {
  display: none !important; }
  @media (min-width: 768px) {
    .mobile-hide {
      display: block !important; } }

@keyframes animateLoaded__ {
  0% {
    opacity: 0;
    filter: blur(10px); }
  100% {
    opacity: 1;
    filter: blur(0); } }

[data-lazy-type].loaded {
  animation: animateLoaded__ 0.1s ease-out forwards; }

.cms-bootstrap [class^="icon-"]:before, .cms-bootstrap [class*=" icon-"]:before {
  font-family: 'Core-icons' !important; }

.EditMode .base-layout-title,
.EditMode .search-layout-title,
.EditMode .category-title,
.EditMode .hp-category-title {
  z-index: 99 !important; }

*:before,
*:after {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.helper-4k {
  max-width: 200rem;
  margin: auto; }

html {
  font-size: 5px; }
  @media (min-width: 480px) {
    html {
      font-size: 5.5px; } }
  @media (min-width: 545px) {
    html {
      font-size: 6px; } }
  @media (min-width: 768px) {
    html {
      font-size: 7.5px; } }
  @media (min-width: 992px) {
    html {
      font-size: 8px; } }
  @media (min-width: 1200px) {
    html {
      font-size: 9px; } }
  @media (min-width: 1400px) {
    html {
      font-size: 10px; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and (-o-min-device-pixel-ratio: 5 / 4), only screen and (min-resolution: 120dpi), only screen and (min-resolution: 1.25dppx) {
  html {
    font-size: 5px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 480px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 480px), only screen and (min-resolution: 120dpi) and (min-width: 480px), only screen and (min-resolution: 1.25dppx) and (min-width: 480px) {
    html {
      font-size: 5.5px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 545px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 545px), only screen and (min-resolution: 120dpi) and (min-width: 545px), only screen and (min-resolution: 1.25dppx) and (min-width: 545px) {
    html {
      font-size: 6px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 768px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 768px), only screen and (min-resolution: 120dpi) and (min-width: 768px), only screen and (min-resolution: 1.25dppx) and (min-width: 768px) {
    html {
      font-size: 7.5px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 992px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 992px), only screen and (min-resolution: 120dpi) and (min-width: 992px), only screen and (min-resolution: 1.25dppx) and (min-width: 992px) {
    html {
      font-size: 8px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1200px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1200px), only screen and (min-resolution: 120dpi) and (min-width: 1200px), only screen and (min-resolution: 1.25dppx) and (min-width: 1200px) {
    html {
      font-size: 7.2px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1400px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1400px), only screen and (min-resolution: 120dpi) and (min-width: 1400px), only screen and (min-resolution: 1.25dppx) and (min-width: 1400px) {
    html {
      font-size: 8px; } }

form,
html,
body {
  font-family: "uni-sans", sans-serif;
  height: 100%;
  color: #000001; }

@media (min-width: 320px) {
  body {
    font-size: 3.2rem; } }

@media (min-width: 480px) {
  body {
    font-size: 2.90909rem; } }

@media (min-width: 545px) {
  body {
    font-size: 2.66667rem; } }

@media (min-width: 768px) {
  body {
    font-size: 2.13333rem; } }

@media (min-width: 992px) {
  body {
    font-size: 2rem; } }

img {
  max-width: 100%;
  height: auto !important; }

@media (min-width: 320px) {
  .pt-5 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-5 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-5 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-5 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-5 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-5 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-5 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-5 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-5 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-5 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-5 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-5 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-5 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-5 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-5 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-5 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-5 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-5 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-5 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-5 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-5 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-5 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-5 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-5 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-5 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-5 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-5 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-5 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-10 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-10 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-10 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-10 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-10 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-10 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-10 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-10 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-10 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-10 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-10 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-10 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-10 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-10 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-10 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-10 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-10 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-10 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-10 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-10 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-10 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-10 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-10 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-10 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-10 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-10 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-10 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-10 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-15 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-15 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-15 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-15 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-15 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-15 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-15 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-15 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-15 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-15 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-15 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-15 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-15 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-15 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-15 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-15 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-15 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-15 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-15 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-15 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-15 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-15 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-15 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-15 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-15 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-15 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-15 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-15 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-20 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-20 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-20 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-20 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-20 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-20 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-20 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-20 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-20 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-20 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-20 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-20 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-20 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-20 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-20 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-20 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-20 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-20 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-20 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-20 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-20 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-20 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-20 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-20 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-20 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-20 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-20 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-20 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-25 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-25 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-25 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-25 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-25 {
    padding-top: 2.5rem; } }

@media (min-width: 320px) {
  .pb-25 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-25 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-25 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-25 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-25 {
    padding-bottom: 2.5rem; } }

@media (min-width: 320px) {
  .mt-25 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-25 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-25 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-25 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-25 {
    margin-top: 2.5rem; } }

@media (min-width: 320px) {
  .mb-25 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-25 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-25 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-25 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-25 {
    margin-bottom: 2.5rem; } }

@media (min-width: 320px) {
  .pt-30 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-30 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-30 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-30 {
    padding-top: 3rem; } }

@media (min-width: 320px) {
  .pb-30 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-30 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-30 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-30 {
    padding-bottom: 3rem; } }

@media (min-width: 320px) {
  .mt-30 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-30 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-30 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-30 {
    margin-top: 3rem; } }

@media (min-width: 320px) {
  .mb-30 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-30 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-30 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-30 {
    margin-bottom: 3rem; } }

@media (min-width: 320px) {
  .pt-35 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-35 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-35 {
    padding-top: 3.5rem; } }

@media (min-width: 320px) {
  .pb-35 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-35 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-35 {
    padding-bottom: 3.5rem; } }

@media (min-width: 320px) {
  .mt-35 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-35 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-35 {
    margin-top: 3.5rem; } }

@media (min-width: 320px) {
  .mb-35 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-35 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-35 {
    margin-bottom: 3.5rem; } }

@media (min-width: 320px) {
  .pt-40 {
    padding-top: 4rem; } }

@media (min-width: 320px) {
  .pb-40 {
    padding-bottom: 4rem; } }

@media (min-width: 320px) {
  .mt-40 {
    margin-top: 4rem; } }

@media (min-width: 320px) {
  .mb-40 {
    margin-bottom: 4rem; } }

@media (min-width: 320px) {
  .pt-45 {
    padding-top: 4.5rem; } }

@media (min-width: 320px) {
  .pb-45 {
    padding-bottom: 4.5rem; } }

@media (min-width: 320px) {
  .mt-45 {
    margin-top: 4.5rem; } }

@media (min-width: 320px) {
  .mb-45 {
    margin-bottom: 4.5rem; } }

@media (min-width: 320px) {
  .pt-50 {
    padding-top: 5rem; } }

@media (min-width: 320px) {
  .pb-50 {
    padding-bottom: 5rem; } }

@media (min-width: 320px) {
  .mt-50 {
    margin-top: 5rem; } }

@media (min-width: 320px) {
  .mb-50 {
    margin-bottom: 5rem; } }

@media (min-width: 320px) {
  .pt-55 {
    padding-top: 5.5rem; } }

@media (min-width: 320px) {
  .pb-55 {
    padding-bottom: 5.5rem; } }

@media (min-width: 320px) {
  .mt-55 {
    margin-top: 5.5rem; } }

@media (min-width: 320px) {
  .mb-55 {
    margin-bottom: 5.5rem; } }

@media (min-width: 320px) {
  .pt-60 {
    padding-top: 6rem; } }

@media (min-width: 320px) {
  .pb-60 {
    padding-bottom: 6rem; } }

@media (min-width: 320px) {
  .mt-60 {
    margin-top: 6rem; } }

@media (min-width: 320px) {
  .mb-60 {
    margin-bottom: 6rem; } }

@media (min-width: 320px) {
  .pt-65 {
    padding-top: 6.5rem; } }

@media (min-width: 320px) {
  .pb-65 {
    padding-bottom: 6.5rem; } }

@media (min-width: 320px) {
  .mt-65 {
    margin-top: 6.5rem; } }

@media (min-width: 320px) {
  .mb-65 {
    margin-bottom: 6.5rem; } }

@media (min-width: 320px) {
  .pt-70 {
    padding-top: 7rem; } }

@media (min-width: 320px) {
  .pb-70 {
    padding-bottom: 7rem; } }

@media (min-width: 320px) {
  .mt-70 {
    margin-top: 7rem; } }

@media (min-width: 320px) {
  .mb-70 {
    margin-bottom: 7rem; } }

@media (min-width: 320px) {
  .pt-75 {
    padding-top: 7.5rem; } }

@media (min-width: 320px) {
  .pb-75 {
    padding-bottom: 7.5rem; } }

@media (min-width: 320px) {
  .mt-75 {
    margin-top: 7.5rem; } }

@media (min-width: 320px) {
  .mb-75 {
    margin-bottom: 7.5rem; } }

@media (min-width: 320px) {
  .pt-80 {
    padding-top: 8rem; } }

@media (min-width: 320px) {
  .pb-80 {
    padding-bottom: 8rem; } }

@media (min-width: 320px) {
  .mt-80 {
    margin-top: 8rem; } }

@media (min-width: 320px) {
  .mb-80 {
    margin-bottom: 8rem; } }

@media (min-width: 320px) {
  .pt-85 {
    padding-top: 8.5rem; } }

@media (min-width: 320px) {
  .pb-85 {
    padding-bottom: 8.5rem; } }

@media (min-width: 320px) {
  .mt-85 {
    margin-top: 8.5rem; } }

@media (min-width: 320px) {
  .mb-85 {
    margin-bottom: 8.5rem; } }

@media (min-width: 320px) {
  .pt-90 {
    padding-top: 9rem; } }

@media (min-width: 320px) {
  .pb-90 {
    padding-bottom: 9rem; } }

@media (min-width: 320px) {
  .mt-90 {
    margin-top: 9rem; } }

@media (min-width: 320px) {
  .mb-90 {
    margin-bottom: 9rem; } }

@media (min-width: 320px) {
  .pt-95 {
    padding-top: 9.5rem; } }

@media (min-width: 320px) {
  .pb-95 {
    padding-bottom: 9.5rem; } }

@media (min-width: 320px) {
  .mt-95 {
    margin-top: 9.5rem; } }

@media (min-width: 320px) {
  .mb-95 {
    margin-bottom: 9.5rem; } }

@media (min-width: 320px) {
  .pt-100 {
    padding-top: 10rem; } }

@media (min-width: 320px) {
  .pb-100 {
    padding-bottom: 10rem; } }

@media (min-width: 320px) {
  .mt-100 {
    margin-top: 10rem; } }

@media (min-width: 320px) {
  .mb-100 {
    margin-bottom: 10rem; } }

h1,
.h1 {
  text-transform: uppercase;
  font-weight: 800;
  color: #fff; }
  @media (min-width: 320px) {
    h1,
    .h1 {
      font-size: 8rem; } }
  @media (min-width: 320px) {
    h1,
    .h1 {
      line-height: 9rem; } }
  h1.letter-spac,
  .h1.letter-spac {
    letter-spacing: 0.3rem; }
  h1.category-banner-title,
  .h1.category-banner-title {
    font-size: 40px;
    line-height: 1.2; }
    @media (min-width: 768px) {
      h1.category-banner-title,
      .h1.category-banner-title {
        font-size: 60px; } }
    @media (min-width: 1200px) {
      h1.category-banner-title,
      .h1.category-banner-title {
        font-size: 8.88889rem; } }
    @media (min-width: 1400px) {
      h1.category-banner-title,
      .h1.category-banner-title {
        font-size: 10rem; } }

h2,
.h2 {
  text-transform: uppercase;
  font-weight: 800;
  color: #fff; }
  @media (min-width: 320px) {
    h2,
    .h2 {
      font-size: 5rem; } }
  @media (min-width: 320px) {
    h2,
    .h2 {
      line-height: 6rem; } }
  h2.letter-spac,
  .h2.letter-spac {
    letter-spacing: 0.3rem; }

h3,
.h3 {
  text-transform: uppercase; }
  @media (min-width: 320px) {
    h3,
    .h3 {
      font-size: 4rem; } }
  @media (min-width: 480px) {
    h3,
    .h3 {
      font-size: 3.63636rem; } }
  @media (min-width: 545px) {
    h3,
    .h3 {
      font-size: 3.33333rem; } }
  @media (min-width: 768px) {
    h3,
    .h3 {
      font-size: 3rem; } }
  @media (min-width: 320px) {
    h3,
    .h3 {
      line-height: 4rem; } }
  @media (min-width: 480px) {
    h3,
    .h3 {
      line-height: 3.63636rem; } }
  @media (min-width: 545px) {
    h3,
    .h3 {
      line-height: 3.33333rem; } }
  @media (min-width: 768px) {
    h3,
    .h3 {
      line-height: 3rem; } }
  h3.font-strong,
  .h3.font-strong {
    font-weight: 800;
    color: #fff; }

h4,
.h4 {
  font-weight: 800;
  color: #000001;
  text-transform: uppercase;
  line-height: 1.2; }
  @media (min-width: 320px) {
    h4,
    .h4 {
      font-size: 3.6rem; } }
  @media (min-width: 480px) {
    h4,
    .h4 {
      font-size: 3.27273rem; } }
  @media (min-width: 545px) {
    h4,
    .h4 {
      font-size: 3rem; } }
  @media (min-width: 768px) {
    h4,
    .h4 {
      font-size: 2.4rem; } }
  @media (min-width: 992px) {
    h4,
    .h4 {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    h4,
    .h4 {
      font-size: 2.2rem; } }
  h4.font-strong,
  .h4.font-strong {
    font-weight: 800;
    color: #fff; }

@media (min-width: 320px) {
  big,
  .perex {
    font-size: 3.6rem; } }

@media (min-width: 480px) {
  big,
  .perex {
    font-size: 3.27273rem; } }

@media (min-width: 545px) {
  big,
  .perex {
    font-size: 3rem; } }

@media (min-width: 768px) {
  big,
  .perex {
    font-size: 2.4rem; } }

@media (min-width: 992px) {
  big,
  .perex {
    font-size: 2.25rem; } }

@media (min-width: 1200px) {
  big,
  .perex {
    font-size: 2.2rem; } }

@media (min-width: 320px) {
  big,
  .perex {
    line-height: 4.4rem; } }

@media (min-width: 480px) {
  big,
  .perex {
    line-height: 4rem; } }

@media (min-width: 545px) {
  big,
  .perex {
    line-height: 3.66667rem; } }

@media (min-width: 768px) {
  big,
  .perex {
    line-height: 2.93333rem; } }

@media (min-width: 992px) {
  big,
  .perex {
    line-height: 2.75rem; } }

@media (min-width: 1200px) {
  big,
  .perex {
    line-height: 2.6rem; } }

@media (min-width: 320px) {
  small,
  .text-small {
    font-size: 2.8rem; } }

@media (min-width: 480px) {
  small,
  .text-small {
    font-size: 2.54545rem; } }

@media (min-width: 545px) {
  small,
  .text-small {
    font-size: 2.33333rem; } }

@media (min-width: 768px) {
  small,
  .text-small {
    font-size: 1.86667rem; } }

@media (min-width: 992px) {
  small,
  .text-small {
    font-size: 1.75rem; } }

@media (min-width: 1200px) {
  small,
  .text-small {
    font-size: 1.55556rem; } }

@media (min-width: 1400px) {
  small,
  .text-small {
    font-size: 1.4rem; } }

@media (min-width: 320px) {
  small,
  .text-small {
    line-height: 3.6rem; } }

@media (min-width: 480px) {
  small,
  .text-small {
    line-height: 3.27273rem; } }

@media (min-width: 545px) {
  small,
  .text-small {
    line-height: 3rem; } }

@media (min-width: 768px) {
  small,
  .text-small {
    line-height: 2.4rem; } }

@media (min-width: 992px) {
  small,
  .text-small {
    line-height: 2.25rem; } }

@media (min-width: 1200px) {
  small,
  .text-small {
    line-height: 2rem; } }

@media (min-width: 1400px) {
  small,
  .text-small {
    line-height: 1.8rem; } }

a {
  color: #000001;
  cursor: pointer; }
  a:focus, a:hover {
    color: black;
    outline: none;
    text-decoration: none; }

.btn {
  display: inline-block;
  border-radius: 30px;
  border: none;
  box-shadow: none;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0.1rem; }
  @media (min-width: 320px) {
    .btn {
      font-size: 3.6rem; } }
  @media (min-width: 480px) {
    .btn {
      font-size: 3.27273rem; } }
  @media (min-width: 545px) {
    .btn {
      font-size: 3rem; } }
  @media (min-width: 768px) {
    .btn {
      font-size: 2.4rem; } }
  @media (min-width: 992px) {
    .btn {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    .btn {
      font-size: 2.2rem; } }
  .btn:focus, .btn:hover {
    text-decoration: none;
    box-shadow: none; }

.btn-primary {
  background: transparent;
  color: #e5004c; }
  .btn-primary:focus, .btn-primary:hover {
    text-decoration: none;
    background: transparent;
    color: #e5004c; }
  @media (min-width: 320px) {
    .btn-primary.small-size {
      font-size: 3.6rem; } }
  @media (min-width: 480px) {
    .btn-primary.small-size {
      font-size: 3.27273rem; } }
  @media (min-width: 545px) {
    .btn-primary.small-size {
      font-size: 3rem; } }
  @media (min-width: 768px) {
    .btn-primary.small-size {
      font-size: 2.4rem; } }
  @media (min-width: 992px) {
    .btn-primary.small-size {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    .btn-primary.small-size {
      font-size: 2rem; } }
  .btn-primary:after {
    content: "\e902";
    display: inline-block;
    font-size: 66%;
    transform: translate(3px, 0rem);
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    margin-top: 3%; }
  .btn-primary:hover:after, .btn-primary:focus:after {
    transform: translate(6px, 0rem); }

.btn-default {
  background: #e5004c;
  color: #000001; }
  .btn-default:focus, .btn-default:hover {
    text-decoration: none;
    background: #cc0044;
    color: #000001; }

/*
.btn-secondary {
  background: $base-white;
  color: $base-black;
  font-size: 14px;
  font-weight: 600;
  @include pux-scale-from("font-size", lg, 14px);
  //padding: 1rem 2.3rem;
  letter-spacing: 0;
  &:focus,
  &:hover{
      text-decoration: none;
      background: $btn-default-background;
      color: $base-white;
  }
}
*/
.arrow-btn:after {
  content: "\e902";
  display: inline-block;
  font-size: 66%;
  transform: translate3d(0.2rem, -0.1rem, 0);
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }

.arrow-btn:hover, .arrow-btn:focus {
  color: #000034; }
  .arrow-btn:hover:after, .arrow-btn:focus:after {
    transform: translate3d(0.6rem, -0.1rem, 0); }

.btn-gray,
.btn-secondary {
  color: #000001;
  font-size: 12px;
  font-weight: 600;
  padding: 1rem 0;
  letter-spacing: 0; }
  @media (min-width: 320px) {
    .btn-gray,
    .btn-secondary {
      font-size: 3.2rem; } }
  @media (min-width: 480px) {
    .btn-gray,
    .btn-secondary {
      font-size: 2.90909rem; } }
  @media (min-width: 545px) {
    .btn-gray,
    .btn-secondary {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    .btn-gray,
    .btn-secondary {
      font-size: 2.13333rem; } }
  @media (min-width: 992px) {
    .btn-gray,
    .btn-secondary {
      font-size: 2rem; } }
  .btn-gray:after,
  .btn-secondary:after {
    content: "\e902";
    display: inline-block;
    font-size: 66%;
    transform: translate(3px, 0rem);
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    margin-top: 3%; }
  .btn-gray:focus, .btn-gray:hover,
  .btn-secondary:focus,
  .btn-secondary:hover {
    text-decoration: none;
    color: #000001; }
    .btn-gray:focus:after, .btn-gray:hover:after,
    .btn-secondary:focus:after,
    .btn-secondary:hover:after {
      transform: translate(6px, 0rem); }
  .btn-gray.big-size,
  .btn-secondary.big-size {
    padding: 1rem 2.4rem;
    font-weight: 400; }
    @media (min-width: 320px) {
      .btn-gray.big-size,
      .btn-secondary.big-size {
        font-size: 3.6rem; } }
    @media (min-width: 480px) {
      .btn-gray.big-size,
      .btn-secondary.big-size {
        font-size: 3.27273rem; } }
    @media (min-width: 545px) {
      .btn-gray.big-size,
      .btn-secondary.big-size {
        font-size: 3rem; } }
    @media (min-width: 768px) {
      .btn-gray.big-size,
      .btn-secondary.big-size {
        font-size: 2.4rem; } }
    @media (min-width: 992px) {
      .btn-gray.big-size,
      .btn-secondary.big-size {
        font-size: 2.25rem; } }
    @media (min-width: 1200px) {
      .btn-gray.big-size,
      .btn-secondary.big-size {
        font-size: 2.2rem; } }

.btn-light-gray {
  background: transparent;
  color: #000001;
  font-weight: 600;
  padding: 1rem 2.3rem;
  letter-spacing: 0; }
  @media (min-width: 320px) {
    .btn-light-gray {
      font-size: 3.2rem; } }
  @media (min-width: 480px) {
    .btn-light-gray {
      font-size: 2.90909rem; } }
  @media (min-width: 545px) {
    .btn-light-gray {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    .btn-light-gray {
      font-size: 2.13333rem; } }
  @media (min-width: 992px) {
    .btn-light-gray {
      font-size: 2rem; } }
  .btn-light-gray:focus, .btn-light-gray:hover {
    text-decoration: underline;
    color: #000001; }

.cross-icon {
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center; }
  .cross-icon:before {
    content: "\e91b";
    display: block;
    margin-right: 1rem;
    font-size: 8px;
    font-weight: 600; }
    @media (min-width: 992px) {
      .cross-icon:before {
        font-size: 1rem; } }
    @media (min-width: 1200px) {
      .cross-icon:before {
        font-size: 0.88889rem; } }
    @media (min-width: 1400px) {
      .cross-icon:before {
        font-size: 0.8rem; } }

.btn-panel {
  background: #fff;
  color: #000001;
  font-size: 12px;
  font-weight: 600;
  padding: 1rem 2rem;
  margin-left: 2rem;
  letter-spacing: 0; }
  @media (min-width: 1200px) {
    .btn-panel {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .btn-panel {
      font-size: 1.4rem; } }
  @media (min-width: 1400px) {
    .btn-panel {
      padding: 1rem 2.8rem;
      font-size: 1.5rem; } }
  .btn-panel-inner {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center; }
    .btn-panel-inner:after {
      content: "\e901";
      display: inline-block;
      font-size: 7px;
      color: #000001;
      margin-left: 10px;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      @media (min-width: 992px) {
        .btn-panel-inner:after {
          font-size: 0.875rem; } }
      @media (min-width: 1200px) {
        .btn-panel-inner:after {
          font-size: 0.77778rem; } }
      @media (min-width: 1400px) {
        .btn-panel-inner:after {
          font-size: 0.7rem; } }
      @media (min-width: 992px) {
        .btn-panel-inner:after {
          margin-left: 2rem; } }
  .btn-panel:focus, .btn-panel:hover {
    text-decoration: none;
    background: #e5004c;
    color: #fff; }
    .btn-panel:focus .btn-panel-inner:after, .btn-panel:hover .btn-panel-inner:after {
      color: #fff; }
  .btn-panel.open {
    z-index: 299; }
    .btn-panel.open .btn-panel-inner:after {
      transform: rotate(180deg); }

.dealers-show-more.open {
  z-index: 299; }
  .dealers-show-more.open .btn-panel-inner:after {
    transform: rotate(180deg); }

.btn-orange {
  background: #dd834c;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  font-style: italic;
  padding: 1rem 2.3rem;
  letter-spacing: 0.1rem; }
  @media (min-width: 1200px) {
    .btn-orange {
      font-size: 1.66667rem; } }
  @media (min-width: 1400px) {
    .btn-orange {
      font-size: 1.5rem; } }
  .btn-orange:focus, .btn-orange:hover {
    text-decoration: none;
    background: #d97437;
    color: #fff; }

.back-btn {
  font-weight: 600;
  font-style: italic;
  font-size: 12px;
  font-weight: 600;
  padding: 1rem 3.8rem;
  letter-spacing: 0;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center; }
  @media (min-width: 768px) {
    .back-btn {
      font-size: 14px; } }
  @media (min-width: 1200px) {
    .back-btn {
      font-size: 1.77778rem; } }
  @media (min-width: 1400px) {
    .back-btn {
      font-size: 1.8rem; } }
  .back-btn:before {
    content: "\e917";
    font-size: 1.2rem;
    margin-right: 1.2rem;
    display: inline-block;
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    transition: transform 150ms linear;
    font-weight: 900; }
  .back-btn:hover, .back-btn:focus {
    text-decoration: none;
    color: #000001; }
    .back-btn:hover:before, .back-btn:focus:before {
      transform: rotate(90deg) translate3d(0%, 40%, 0); }

.btn-dark {
  height: 55px;
  color: #fff;
  padding: 0 20px;
  background-color: rgba(0, 0, 1, 0.11);
  border-radius: 28px;
  display: inline-flex;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center; }
  @media (min-width: 1200px) {
    .btn-dark {
      border-radius: 3.11111rem; } }
  @media (min-width: 1400px) {
    .btn-dark {
      border-radius: 2.8rem; } }
  @media (min-width: 1200px) {
    .btn-dark {
      padding: 0 2.8rem; } }
  .btn-dark:hover, .btn-dark:focus {
    color: #fff;
    background-color: rgba(0, 0, 1, 0.4); }

.image-top-center {
  position: top center !important; }

.image-top-left {
  position: top left !important; }

.image-top-right {
  position: top left !important; }

.image-bottom-center {
  position: bottom center !important; }

.image-bottom-left {
  position: bottom left !important; }

.image-bottom-right {
  position: bottom right !important; }

main {
  min-height: 40rem; }
  main ol {
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: my-ol; }
    main ol li {
      position: relative;
      margin: 0 0 1.5rem 0;
      padding: 0 0 0 2rem; }
      main ol li:before {
        content: counter(my-ol) ".";
        counter-increment: my-ol;
        color: #e5004c;
        position: absolute;
        left: 0;
        top: 3px;
        font-weight: bold; }
  main ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    main ul li {
      margin: 0 0 1.5rem 0;
      padding: 0 0 0 1.5rem;
      position: relative; }
      main ul li:before {
        content: "";
        width: 6px;
        height: 6px;
        background: #000001;
        position: absolute;
        top: 6px;
        left: 0;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%; }
  main table {
    width: 100%; }
    main table tr {
      border-bottom: 1px solid #f1f1f1; }
      main table tr td {
        padding: 1rem 1.5rem; }
      main table tr:nth-child(even) {
        background: #f8f8f8; }
  main .respo-table table {
    width: 100%; }
    main .respo-table table thead {
      display: none; }
      @media (min-width: 768px) {
        main .respo-table table thead {
          display: table-header-group; } }
      main .respo-table table thead tr {
        border-bottom: 1px solid #f1f1f1; }
        main .respo-table table thead tr th {
          font-weight: bold;
          padding: 1rem 1.5rem; }
    main .respo-table table tbody tr {
      display: block;
      border: 1px solid #f1f1f1;
      border-bottom: none;
      margin: 0 0 2rem 0; }
      main .respo-table table tbody tr:nth-child(even) {
        background: #f8f8f8; }
      @media (min-width: 768px) {
        main .respo-table table tbody tr {
          display: table-row;
          margin: 0;
          border: none;
          border-bottom: 1px solid #f1f1f1; } }
      main .respo-table table tbody tr td {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: space-between;
        -moz-box-pack: space-between;
        -ms-flex-pack: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid #f1f1f1; }
        @media (min-width: 768px) {
          main .respo-table table tbody tr td {
            display: table-cell;
            border: none;
            width: auto; } }
        main .respo-table table tbody tr td:before {
          content: attr(data-th);
          display: block;
          text-align: left;
          font-family: "uni-sans", sans-serif !important;
          margin: 0 1.5rem 0 0; }
          @media (min-width: 768px) {
            main .respo-table table tbody tr td:before {
              display: none; } }

.small-bullets-list ul li {
  position: relative;
  margin-bottom: 0.2rem; }
  .small-bullets-list ul li:before {
    content: "";
    width: 3px;
    height: 3px;
    top: 8px; }
    @media (min-width: 1200px) {
      .small-bullets-list ul li:before {
        width: 0.33333rem; } }
    @media (min-width: 1400px) {
      .small-bullets-list ul li:before {
        width: 0.3rem; } }
    @media (min-width: 1200px) {
      .small-bullets-list ul li:before {
        height: 0.33333rem; } }
    @media (min-width: 1400px) {
      .small-bullets-list ul li:before {
        height: 0.3rem; } }
    @media (min-width: 1200px) {
      .small-bullets-list ul li:before {
        top: 1rem; } }
    @media (min-width: 1400px) {
      .small-bullets-list ul li:before {
        top: 0.9rem; } }

.title-lineheight h1,
.title-lineheight h2,
.title-lineheight h3,
.title-lineheight h4 {
  line-height: 1.4; }

.shadow-title h1,
.shadow-title h2,
.shadow-title h3,
.time-line-title h1,
.time-line-title h2,
.time-line-title h3,
.category-title h1,
.category-title h2,
.category-title h3,
.widget-image-right-picture-title h1,
.widget-image-right-picture-title h2,
.widget-image-right-picture-title h3,
.category-top-banner-content-title h1,
.category-top-banner-content-title h2,
.category-top-banner-content-title h3,
.collection-preview h1,
.collection-preview h2,
.collection-preview h3,
.category-top-bar-title h1,
.category-top-bar-title h2,
.category-top-bar-title h3,
.product-accessories-title h1,
.product-accessories-title h2,
.product-accessories-title h3,
.base-layout-title h1,
.base-layout-title h2,
.base-layout-title h3,
.search-layout-title h1,
.search-layout-title h2,
.search-layout-title h3 {
  text-shadow: 0 0 136px rgba(0, 0, 0, 0.23); }

label {
  display: block;
  margin: 1rem 0 0.5rem 0;
  font-size: 2.8rem; }
  @media (min-width: 480px) {
    label {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    label {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    label {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    label {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    label {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    label {
      font-size: 1.4rem; } }

.form-control,
textarea,
input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 0 1.5rem;
  border: 1px solid #fff;
  background: #fff;
  color: #000001;
  font-size: 2.8rem; }
  @media (min-width: 320px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="password"] {
      height: 8rem; } }
  @media (min-width: 480px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="password"] {
      height: 7.27273rem; } }
  @media (min-width: 545px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="password"] {
      height: 6.66667rem; } }
  @media (min-width: 768px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="password"] {
      height: 5.33333rem; } }
  @media (min-width: 992px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="password"] {
      height: 5rem; } }
  @media (min-width: 1200px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="password"] {
      height: 4.44444rem; } }
  @media (min-width: 1400px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="password"] {
      height: 4rem; } }
  @media (min-width: 480px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="password"] {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="password"] {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="password"] {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="password"] {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="password"] {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="password"] {
      font-size: 1.4rem; } }
  .form-control::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder,
  input[type="text"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #000; }
  .form-control:-moz-placeholder,
  textarea:-moz-placeholder,
  input[type="text"]:-moz-placeholder,
  input[type="password"]:-moz-placeholder {
    color: #000; }
  .form-control::-moz-placeholder,
  textarea::-moz-placeholder,
  input[type="text"]::-moz-placeholder,
  input[type="password"]::-moz-placeholder {
    color: #000; }
  .form-control:-ms-input-placeholder,
  textarea:-ms-input-placeholder,
  input[type="text"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #000; }
  .form-control:focus,
  textarea:focus,
  input[type="text"]:focus,
  input[type="password"]:focus {
    outline: 0;
    border: 1px solid #e5004c; }

textarea {
  padding: 1.5rem textarea; }
  @media (min-width: 320px) {
    textarea {
      height: 20rem; } }
  @media (min-width: 480px) {
    textarea {
      height: 18.18182rem; } }
  @media (min-width: 545px) {
    textarea {
      height: 16.66667rem; } }
  @media (min-width: 768px) {
    textarea {
      height: 13.33333rem; } }
  @media (min-width: 992px) {
    textarea {
      height: 12.5rem; } }
  @media (min-width: 1200px) {
    textarea {
      height: 11.11111rem; } }
  @media (min-width: 1400px) {
    textarea {
      height: 10rem; } }
  textarea :focus {
    border: 1px solid #e5004c; }

input[type="submit"] {
  cursor: pointer; }

.required label:before {
  content: "*";
  display: inline-block;
  vertical-align: 0.6rem;
  margin: 0 2px 0 0;
  font-size: 2rem; }
  @media (min-width: 480px) {
    .required label:before {
      font-size: 1.81818rem; } }
  @media (min-width: 545px) {
    .required label:before {
      font-size: 1.66667rem; } }
  @media (min-width: 768px) {
    .required label:before {
      font-size: 1.33333rem; } }
  @media (min-width: 992px) {
    .required label:before {
      font-size: 1.25rem; } }
  @media (min-width: 1200px) {
    .required label:before {
      font-size: 1.11111rem; } }
  @media (min-width: 1400px) {
    .required label:before {
      font-size: 1rem; } }

label.required:after {
  content: "*";
  display: inline-block;
  vertical-align: 0.6rem;
  margin: 0 2px 0 0;
  color: #e5004c;
  font-size: 2rem; }
  @media (min-width: 480px) {
    label.required:after {
      font-size: 1.81818rem; } }
  @media (min-width: 545px) {
    label.required:after {
      font-size: 1.66667rem; } }
  @media (min-width: 768px) {
    label.required:after {
      font-size: 1.33333rem; } }
  @media (min-width: 992px) {
    label.required:after {
      font-size: 1.25rem; } }
  @media (min-width: 1200px) {
    label.required:after {
      font-size: 1.11111rem; } }
  @media (min-width: 1400px) {
    label.required:after {
      font-size: 1rem; } }

.file .uploader-upload {
  display: none; }

.file label {
  display: inline-block;
  padding: 1rem 2rem;
  text-transform: uppercase;
  color: #fff;
  background: #e5004c;
  cursor: pointer;
  font-size: 2.8rem;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  max-width: 24rem;
  margin: 0;
  width: 100%;
  text-align: center;
  cursor: pointer;
  letter-spacing: 1px;
  outline: 0 !important; }
  @media (min-width: 480px) {
    .file label {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .file label {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .file label {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .file label {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .file label {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .file label {
      font-size: 1.4rem; } }

.file .file-name {
  padding: 0.5rem;
  background: #a00136;
  color: #000001; }

.Error select,
.Error .selectric,
.Error input[type="text"],
.Error textarea {
  border: 1px solid #d40511 !important;
  -webkit-box-shadow: inset 0 0 0 1px #d40511;
  -moz-box-shadow: inset 0 0 0 1px #d40511;
  box-shadow: inset 0 0 0 1px #d40511;
  -webkit-animation-name: blink;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: ease;
  -webkit-animation-iteration-count: 2;
  -webkit-animation-direction: normal;
  -moz-animation-name: blink;
  -moz-animation-duration: 1s;
  -moz-animation-timing-function: ease;
  -moz-animation-iteration-count: 2;
  -moz-animation-direction: normal;
  -o-animation-name: blink;
  -o-animation-duration: 1s;
  -o-animation-timing-function: ease;
  -o-animation-iteration-count: 2;
  -o-animation-direction: normal;
  animation-name: blink;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: 2;
  animation-direction: normal;
  padding: 0 10px !important; }

@-webkit-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@-moz-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@-ms-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

.EditingFormErrorLabel {
  font-size: 2.8rem;
  color: #d40511;
  display: block;
  margin: 0.5rem 0 0 0; }
  @media (min-width: 480px) {
    .EditingFormErrorLabel {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .EditingFormErrorLabel {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .EditingFormErrorLabel {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .EditingFormErrorLabel {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .EditingFormErrorLabel {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .EditingFormErrorLabel {
      font-size: 1.4rem; } }

.ErrorLabel {
  color: #d40511;
  display: block;
  margin: 0.5rem 0; }

.radio input[type="radio"] {
  display: none; }
  .radio input[type="radio"] + label {
    position: relative;
    padding: 0 0 25px 25px;
    display: inline-block;
    font-family: "Open Sans", sans-serif;
    margin: 0;
    font-size: 12px;
    cursor: pointer; }
    @media (min-width: 1200px) {
      .radio input[type="radio"] + label {
        font-size: 1.66667rem; } }
    @media (min-width: 1400px) {
      .radio input[type="radio"] + label {
        font-size: 1.5rem; } }
    @media (min-width: 1200px) {
      .radio input[type="radio"] + label {
        padding: 0 0 2.5rem 2.5rem; } }
    .radio input[type="radio"] + label:before {
      content: "";
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      width: 14px;
      height: 14px;
      background: #fff;
      display: block;
      position: absolute;
      left: 0;
      top: 2px;
      border: 1px solid #c3c2c2;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      @media (min-width: 1200px) {
        .radio input[type="radio"] + label:before {
          width: 1.55556rem; } }
      @media (min-width: 1400px) {
        .radio input[type="radio"] + label:before {
          width: 1.4rem; } }
      @media (min-width: 1200px) {
        .radio input[type="radio"] + label:before {
          height: 1.55556rem; } }
      @media (min-width: 1400px) {
        .radio input[type="radio"] + label:before {
          height: 1.4rem; } }
      @media (min-width: 1200px) {
        .radio input[type="radio"] + label:before {
          top: 0.44444rem; } }
      @media (min-width: 1400px) {
        .radio input[type="radio"] + label:before {
          top: 0.4rem; } }
      @media (min-width: 1200px) {
        .radio input[type="radio"] + label:before {
          border-width: 0.11111rem; } }
      @media (min-width: 1400px) {
        .radio input[type="radio"] + label:before {
          border-width: 0.1rem; } }
    .radio input[type="radio"] + label:after {
      content: "";
      display: block;
      left: 4px;
      top: 6px;
      width: 6px;
      height: 6px;
      background: #e5004c;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      position: absolute;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      @media (min-width: 1200px) {
        .radio input[type="radio"] + label:after {
          width: 0.66667rem; } }
      @media (min-width: 1400px) {
        .radio input[type="radio"] + label:after {
          width: 0.6rem; } }
      @media (min-width: 1200px) {
        .radio input[type="radio"] + label:after {
          height: 0.66667rem; } }
      @media (min-width: 1400px) {
        .radio input[type="radio"] + label:after {
          height: 0.6rem; } }
      @media (min-width: 1200px) {
        .radio input[type="radio"] + label:after {
          left: 0.44444rem; } }
      @media (min-width: 1400px) {
        .radio input[type="radio"] + label:after {
          left: 0.4rem; } }
      @media (min-width: 1200px) {
        .radio input[type="radio"] + label:after {
          top: 0.88889rem; } }
      @media (min-width: 1400px) {
        .radio input[type="radio"] + label:after {
          top: 0.8rem; } }
  .radio input[type="radio"]:checked + label:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }

.radio-black input[type="radio"] {
  display: none; }
  .radio-black input[type="radio"] + label {
    position: relative;
    padding: 0 0 25px 30px;
    display: inline-block;
    font-family: "Open Sans", sans-serif;
    margin: 0;
    font-size: 12px;
    cursor: pointer; }
    @media (min-width: 1200px) {
      .radio-black input[type="radio"] + label {
        font-size: 1.66667rem; } }
    @media (min-width: 1400px) {
      .radio-black input[type="radio"] + label {
        font-size: 1.5rem; } }
    @media (min-width: 1200px) {
      .radio-black input[type="radio"] + label {
        padding-bottom: 2.5rem; } }
    @media (min-width: 1200px) {
      .radio-black input[type="radio"] + label {
        padding-left: 3.33333rem; } }
    @media (min-width: 1400px) {
      .radio-black input[type="radio"] + label {
        padding-left: 3rem; } }
    .radio-black input[type="radio"] + label:before {
      content: "";
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      background: #fff;
      display: block;
      position: absolute;
      left: 0;
      top: 5px;
      border: 1px solid #c4c3c3;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      @media (min-width: 1200px) {
        .radio-black input[type="radio"] + label:before {
          border-width: 0.11111rem; } }
      @media (min-width: 1400px) {
        .radio-black input[type="radio"] + label:before {
          border-width: 0.1rem; } }
    .radio-black input[type="radio"] + label:after {
      content: "";
      display: block;
      left: 4px;
      top: 9px;
      width: 10px;
      height: 10px;
      background: #000001;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      position: absolute;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
  .radio-black input[type="radio"]:checked + label:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }

.checkbox input[type="checkbox"] {
  display: none; }
  .checkbox input[type="checkbox"] + label {
    position: relative;
    padding: 0 0 10px 25px;
    margin: 0;
    display: inline-block;
    cursor: pointer; }
    @media (min-width: 1200px) {
      .checkbox input[type="checkbox"] + label {
        padding-bottom: 0.66667rem; } }
    @media (min-width: 1400px) {
      .checkbox input[type="checkbox"] + label {
        padding-bottom: 0.6rem; } }
    @media (min-width: 1200px) {
      .checkbox input[type="checkbox"] + label {
        padding-left: 3.33333rem; } }
    @media (min-width: 1400px) {
      .checkbox input[type="checkbox"] + label {
        padding-left: 3rem; } }
    .checkbox input[type="checkbox"] + label:before {
      content: "";
      width: 16px;
      height: 16px;
      background: #fff;
      display: block;
      position: absolute;
      left: 0;
      top: 2px;
      border: 1px solid #c3c2c2;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      border-radius: 5px;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      @media (min-width: 992px) {
        .checkbox input[type="checkbox"] + label:before {
          width: 2rem; } }
      @media (min-width: 1200px) {
        .checkbox input[type="checkbox"] + label:before {
          width: 1.77778rem; } }
      @media (min-width: 1400px) {
        .checkbox input[type="checkbox"] + label:before {
          width: 1.6rem; } }
      @media (min-width: 992px) {
        .checkbox input[type="checkbox"] + label:before {
          height: 2rem; } }
      @media (min-width: 1200px) {
        .checkbox input[type="checkbox"] + label:before {
          height: 1.77778rem; } }
      @media (min-width: 1400px) {
        .checkbox input[type="checkbox"] + label:before {
          height: 1.6rem; } }
      @media (min-width: 992px) {
        .checkbox input[type="checkbox"] + label:before {
          border-width: 0.125rem; } }
      @media (min-width: 1200px) {
        .checkbox input[type="checkbox"] + label:before {
          border-width: 0.11111rem; } }
      @media (min-width: 1400px) {
        .checkbox input[type="checkbox"] + label:before {
          border-width: 0.1rem; } }
      @media (min-width: 992px) {
        .checkbox input[type="checkbox"] + label:before {
          border-radius: 0.625rem; } }
      @media (min-width: 1200px) {
        .checkbox input[type="checkbox"] + label:before {
          border-radius: 0.55556rem; } }
      @media (min-width: 1400px) {
        .checkbox input[type="checkbox"] + label:before {
          border-radius: 0.5rem; } }
      @media (min-width: 1200px) {
        .checkbox input[type="checkbox"] + label:before {
          top: 0.22222rem; } }
      @media (min-width: 1400px) {
        .checkbox input[type="checkbox"] + label:before {
          top: 0.6rem; } }
    .checkbox input[type="checkbox"] + label:after {
      content: "";
      display: block;
      left: 3px;
      top: 5px;
      width: 10px;
      height: 10px;
      background: #e5004c;
      position: absolute;
      border-radius: 3px;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      @media (min-width: 992px) {
        .checkbox input[type="checkbox"] + label:after {
          width: 1.25rem; } }
      @media (min-width: 1200px) {
        .checkbox input[type="checkbox"] + label:after {
          width: 1.11111rem; } }
      @media (min-width: 1400px) {
        .checkbox input[type="checkbox"] + label:after {
          width: 1rem; } }
      @media (min-width: 992px) {
        .checkbox input[type="checkbox"] + label:after {
          height: 1.25rem; } }
      @media (min-width: 1200px) {
        .checkbox input[type="checkbox"] + label:after {
          height: 1.11111rem; } }
      @media (min-width: 1400px) {
        .checkbox input[type="checkbox"] + label:after {
          height: 1rem; } }
      @media (min-width: 992px) {
        .checkbox input[type="checkbox"] + label:after {
          border-radius: 0.375rem; } }
      @media (min-width: 1200px) {
        .checkbox input[type="checkbox"] + label:after {
          border-radius: 0.33333rem; } }
      @media (min-width: 1400px) {
        .checkbox input[type="checkbox"] + label:after {
          border-radius: 0.3rem; } }
      @media (min-width: 1200px) {
        .checkbox input[type="checkbox"] + label:after {
          top: 0.66667rem;
          left: 0.33333rem; } }
      @media (min-width: 1400px) {
        .checkbox input[type="checkbox"] + label:after {
          top: 0.9rem;
          left: 0.3rem; } }
  .checkbox input[type="checkbox"]:checked + label:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }

.row-form {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -1rem; }

.form-item-100,
.form-item-50,
.form-item-33,
.form-item-25 {
  padding: 0 1rem 1rem 1rem; }

.form-item-100 {
  width: 100%; }

.form-item-50 {
  width: 100%; }
  @media (min-width: 768px) {
    .form-item-50 {
      width: 50%; } }

.form-item-33 {
  width: 100%; }
  @media (min-width: 768px) {
    .form-item-33 {
      width: 33.33%; } }

.form-item-25 {
  width: 100%; }
  @media (min-width: 768px) {
    .form-item-25 {
      width: 25%; } }

.topImage {
  position: relative;
  background-size: cover !important;
  background-position: 50% 0 !important;
  background-repeat: no-repeat !important; }
  @media (min-width: 1200px) {
    .topImage {
      background-position: 50% 50% !important;
      height: 77rem; } }
  .topImage .pux-container {
    padding-left: 0px;
    padding-right: 0px; }
    @media (min-width: 1200px) {
      .topImage .pux-container {
        padding-left: 10px;
        padding-right: 10px; } }
  .topImage .topImage-content-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 0 35px 0; }
    @media (min-width: 1200px) {
      .topImage .topImage-content-inner {
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        height: 77rem;
        padding: 35px 0; } }
  .topImage .topImage-image {
    position: relative;
    z-index: 2;
    max-width: 96rem;
    animation: topImageSlide 10s linear forwards; }
    @media (min-width: 320px) and (max-width: 1199px) {
      .topImage .topImage-image {
        display: none; } }
    .topImage .topImage-image-respo {
      width: 100%; }
      @media (min-width: 1200px) {
        .topImage .topImage-image-respo {
          display: none; } }
      .topImage .topImage-image-respo img {
        width: 100%; }
  .topImage .topImage-text {
    position: relative;
    z-index: 3;
    flex-shrink: 0;
    background-color: #457388;
    width: 100%;
    padding: 4rem 4rem 4rem 8rem;
    margin-bottom: -35px; }
    @media (min-width: 1200px) {
      .topImage .topImage-text {
        background-color: transparent;
        width: auto;
        padding: 0; } }
    .topImage .topImage-text .topImage-product-name {
      text-transform: uppercase;
      font-weight: 400;
      color: #fff; }
      @media (min-width: 320px) {
        .topImage .topImage-text .topImage-product-name {
          font-size: 8.8rem; } }
      @media (min-width: 480px) {
        .topImage .topImage-text .topImage-product-name {
          font-size: 8rem; } }
      @media (min-width: 545px) {
        .topImage .topImage-text .topImage-product-name {
          font-size: 7.33333rem; } }
      @media (min-width: 768px) {
        .topImage .topImage-text .topImage-product-name {
          font-size: 6.6rem; } }
      @media (min-width: 320px) {
        .topImage .topImage-text .topImage-product-name {
          line-height: 9rem; } }
      @media (min-width: 480px) {
        .topImage .topImage-text .topImage-product-name {
          line-height: 8.18182rem; } }
      @media (min-width: 545px) {
        .topImage .topImage-text .topImage-product-name {
          line-height: 7.5rem; } }
      @media (min-width: 768px) {
        .topImage .topImage-text .topImage-product-name {
          line-height: 7rem; } }
    .topImage .topImage-text .topImage-product-border {
      background-color: #fff;
      width: 141px;
      height: 5px;
      margin: 2rem 0 2.3rem 0; }
    .topImage .topImage-text .topImage-product-description {
      color: #fff;
      max-width: 466px;
      width: 100%;
      margin-bottom: 4rem;
      opacity: 0;
      animation: topImageFadeIn 750ms linear forwards;
      animation-delay: 1s; }
      @media (min-width: 320px) {
        .topImage .topImage-text .topImage-product-description {
          font-size: 3.2rem; } }
      @media (min-width: 480px) {
        .topImage .topImage-text .topImage-product-description {
          font-size: 2.90909rem; } }
      @media (min-width: 545px) {
        .topImage .topImage-text .topImage-product-description {
          font-size: 2.66667rem; } }
      @media (min-width: 768px) {
        .topImage .topImage-text .topImage-product-description {
          font-size: 2.13333rem; } }
      @media (min-width: 992px) {
        .topImage .topImage-text .topImage-product-description {
          font-size: 2rem; } }
      @media (min-width: 1200px) {
        .topImage .topImage-text .topImage-product-description {
          font-size: 1.8rem; } }
      @media (min-width: 320px) {
        .topImage .topImage-text .topImage-product-description {
          line-height: 4rem; } }
      @media (min-width: 480px) {
        .topImage .topImage-text .topImage-product-description {
          line-height: 3.63636rem; } }
      @media (min-width: 545px) {
        .topImage .topImage-text .topImage-product-description {
          line-height: 3.33333rem; } }
      @media (min-width: 768px) {
        .topImage .topImage-text .topImage-product-description {
          line-height: 2.66667rem; } }
      @media (min-width: 992px) {
        .topImage .topImage-text .topImage-product-description {
          line-height: 2.5rem; } }
      @media (min-width: 1200px) {
        .topImage .topImage-text .topImage-product-description {
          line-height: 2.4rem; } }
    .topImage .topImage-text .btn-primary {
      border-radius: 43px;
      padding: 3.5rem 5.5rem 3.5rem 3.5rem;
      opacity: 0;
      animation: topImageFadeIn 750ms linear forwards;
      animation-delay: 1.5s; }
      @media (min-width: 320px) {
        .topImage .topImage-text .btn-primary {
          font-size: 3.2rem; } }
      @media (min-width: 480px) {
        .topImage .topImage-text .btn-primary {
          font-size: 2.90909rem; } }
      @media (min-width: 545px) {
        .topImage .topImage-text .btn-primary {
          font-size: 2.66667rem; } }
      @media (min-width: 768px) {
        .topImage .topImage-text .btn-primary {
          font-size: 2.13333rem; } }
      @media (min-width: 992px) {
        .topImage .topImage-text .btn-primary {
          font-size: 2rem; } }
      .topImage .topImage-text .btn-primary:after {
        position: relative;
        top: 1px;
        left: 10px;
        margin-top: 0; }

@keyframes topImageSlide {
  0% {
    transform: translate3d(-5rem, 0, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes topImageFadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.dashboard-image-right {
  display: flex;
  flex-direction: column;
  width: 100%; }
  @media (min-width: 1200px) {
    .dashboard-image-right {
      padding-top: 12rem;
      -webkit-box-pack: flex-end;
      -moz-box-pack: flex-end;
      -ms-flex-pack: flex-end;
      -webkit-justify-content: flex-end;
      justify-content: flex-end;
      -webkit-box-align: flex-start;
      -moz-box-align: flex-start;
      -ms-flex-align: flex-start;
      -webkit-align-items: flex-start;
      align-items: flex-start;
      -moz-flex-flow: row nowrap;
      -ms-flex-direction: row;
      -ms-flex-wrap: nowrap;
      -webkit-flex-flow: row nowrap;
      flex-flow: row nowrap; } }
  @media (min-width: 1400px) {
    .dashboard-image-right {
      padding-left: 6.6rem;
      padding-right: 5.6rem;
      padding-top: 10rem; } }
  .dashboard-image-right-container {
    max-width: 166rem;
    position: relative;
    margin: 0 auto; }
  .dashboard-image-right-picture {
    flex-shrink: 0;
    position: relative;
    text-align: right; }
    .dashboard-image-right-picture img {
      margin-left: auto; }
    @media (min-width: 1200px) {
      .dashboard-image-right-picture {
        text-align: left;
        margin-left: 0;
        max-width: 86.66667rem;
        width: 100%; }
        .dashboard-image-right-picture img {
          margin-left: 0; } }
    @media (min-width: 1400px) {
      .dashboard-image-right-picture {
        max-width: 87rem; } }
  .dashboard-image-right-content {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;
    padding: 30px 23px 30px 23px; }
    @media (min-width: 545px) {
      .dashboard-image-right-content {
        max-width: 76%;
        margin-top: -25rem; } }
    @media (min-width: 1200px) {
      .dashboard-image-right-content {
        max-width: 64.44444rem;
        position: absolute;
        top: 6rem;
        left: 7.77778rem;
        transform: none;
        padding: 7rem 2rem 7rem 7rem;
        width: 100%;
        margin-top: 0; } }
    @media (min-width: 1400px) {
      .dashboard-image-right-content {
        max-width: 74.7rem;
        left: 5.5rem;
        padding: 8rem 9rem 8rem 8rem; } }
    @media (min-width: 768px) {
      .dashboard-image-right-content-text {
        max-width: 100%; } }
    .dashboard-image-right-content-text h2 {
      color: #000001;
      font-size: 28px;
      font-weight: 400;
      line-height: 33px;
      text-transform: uppercase;
      margin-bottom: 3rem; }
      .dashboard-image-right-content-text h2 strong {
        font-size: 32px;
        font-weight: 800; }
    .dashboard-image-right-content-text a {
      text-align: left; }
    .dashboard-image-right-content-image {
      -webkit-flex: 0 1 40%;
      -moz-flex: 0 1 40%;
      -ms-flex: 0 1 40%;
      -webkit-flex: 0 1 40%;
      flex: 0 1 40%;
      max-width: 40%;
      -webkit-box-align: flex-end;
      -moz-box-align: flex-end;
      -ms-flex-align: flex-end;
      -webkit-align-items: flex-end;
      align-items: flex-end;
      -webkit-box-pack: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      display: none; }
      @media (min-width: 768px) {
        .dashboard-image-right-content-image {
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex; } }
      .dashboard-image-right-content-image img {
        max-width: 70%; }

.dashboard-image-left {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 6rem;
  max-width: 172.5rem; }
  @media (min-width: 545px) {
    .dashboard-image-left {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -moz-flex-flow: column nowrap;
      -ms-flex-direction: column;
      -ms-flex-wrap: nowrap;
      -webkit-flex-flow: column nowrap;
      flex-flow: column nowrap; } }
  @media (min-width: 1200px) {
    .dashboard-image-left {
      -webkit-box-pack: flex-start;
      -moz-box-pack: flex-start;
      -ms-flex-pack: flex-start;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      -webkit-box-align: flex-start;
      -moz-box-align: flex-start;
      -ms-flex-align: flex-start;
      -webkit-align-items: flex-start;
      align-items: flex-start;
      -moz-flex-flow: row nowrap;
      -ms-flex-direction: row;
      -ms-flex-wrap: nowrap;
      -webkit-flex-flow: row nowrap;
      flex-flow: row nowrap;
      padding-top: 12rem; } }
  .dashboard-image-left-container {
    position: relative; }
  .dashboard-image-left-picture {
    position: relative; }
    @media (min-width: 768px) {
      .dashboard-image-left-picture {
        margin-right: 90px; } }
    @media (min-width: 1200px) {
      .dashboard-image-left-picture {
        margin-right: 0;
        max-width: 100rem;
        width: 100%; } }
    @media (min-width: 1400px) {
      .dashboard-image-left-picture {
        max-width: 115rem; } }
  .dashboard-image-left-content {
    background-color: #fff;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: flex-end;
    -moz-box-align: flex-end;
    -ms-flex-align: flex-end;
    -webkit-align-items: flex-end;
    align-items: flex-end;
    padding: 30px 23px 30px 23px;
    align-self: flex-end;
    width: 100%; }
    @media (min-width: 545px) {
      .dashboard-image-left-content {
        margin-top: -25rem;
        max-width: 76%; } }
    @media (min-width: 1200px) {
      .dashboard-image-left-content {
        max-width: 64.44444rem;
        position: absolute;
        top: 16rem;
        right: 0;
        transform: none;
        padding: 7rem 7rem 7rem 6rem;
        margin-top: 0; } }
    @media (min-width: 1400px) {
      .dashboard-image-left-content {
        max-width: 72rem;
        top: 16rem;
        padding: 8rem 9rem 8rem 8rem; } }
    .dashboard-image-left-content-text {
      order: 2; }
      @media (min-width: 768px) {
        .dashboard-image-left-content-text {
          width: 100%; } }
      .dashboard-image-left-content-text h2 {
        font-size: 20px;
        color: #000001;
        font-size: 28px;
        font-weight: 400;
        line-height: 33px;
        text-transform: uppercase;
        margin-bottom: 3rem; }
        .dashboard-image-left-content-text h2 strong {
          font-size: 32px;
          font-weight: 800; }
      .dashboard-image-left-content-text a {
        text-align: left; }
    .dashboard-image-left-content-image {
      -webkit-flex: 0 1 40%;
      -moz-flex: 0 1 40%;
      -ms-flex: 0 1 40%;
      -webkit-flex: 0 1 40%;
      flex: 0 1 40%;
      max-width: 40%;
      -webkit-box-align: flex-end;
      -moz-box-align: flex-end;
      -ms-flex-align: flex-end;
      -webkit-align-items: flex-end;
      align-items: flex-end;
      -webkit-box-pack: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      display: none; }
      @media (min-width: 768px) {
        .dashboard-image-left-content-image {
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex; } }
      .dashboard-image-left-content-image img {
        max-width: 70%; }

.dashboard-btn {
  max-width: 100%;
  white-space: normal;
  padding: 1rem 0; }
  @media (min-width: 320px) {
    .dashboard-btn {
      font-size: 3.6rem; } }
  @media (min-width: 480px) {
    .dashboard-btn {
      font-size: 3.27273rem; } }
  @media (min-width: 545px) {
    .dashboard-btn {
      font-size: 3rem; } }
  @media (min-width: 768px) {
    .dashboard-btn {
      font-size: 2.4rem; } }
  @media (min-width: 992px) {
    .dashboard-btn {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    .dashboard-btn {
      font-size: 2.2rem; } }
  .dashboard-btn.mt-base {
    margin-top: 7px; }
    @media (min-width: 1200px) {
      .dashboard-btn.mt-base {
        margin-top: 2.22222rem; } }
    @media (min-width: 1400px) {
      .dashboard-btn.mt-base {
        margin-top: 1rem; } }

.dashboard-btn-2 {
  max-width: 100%;
  white-space: normal;
  padding: 1rem 0rem; }
  @media (min-width: 320px) {
    .dashboard-btn-2 {
      font-size: 3.6rem; } }
  @media (min-width: 480px) {
    .dashboard-btn-2 {
      font-size: 3.27273rem; } }
  @media (min-width: 545px) {
    .dashboard-btn-2 {
      font-size: 3rem; } }
  @media (min-width: 768px) {
    .dashboard-btn-2 {
      font-size: 2.4rem; } }
  @media (min-width: 992px) {
    .dashboard-btn-2 {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    .dashboard-btn-2 {
      font-size: 2.2rem; } }
  @media (min-width: 1200px) {
    .dashboard-btn-2 {
      padding: 1rem 0rem; } }

@media (min-width: 320px) {
  .dashboards-wrapper {
    padding-bottom: 20rem; } }

@media (min-width: 480px) {
  .dashboards-wrapper {
    padding-bottom: 18.18182rem; } }

@media (min-width: 545px) {
  .dashboards-wrapper {
    padding-bottom: 16.66667rem; } }

@media (min-width: 768px) {
  .dashboards-wrapper {
    padding-bottom: 14rem; } }

.sectionHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 0 6rem 0;
  width: 100%; }
  @media (min-width: 992px) {
    .sectionHeader {
      flex-direction: row-reverse;
      padding: 5rem 0 6rem 0; } }
  .sectionHeader .sectionHeader-image {
    position: relative;
    width: 100%;
    box-shadow: 0 8px 65px rgba(0, 0, 0, 0.1); }
    @media (min-width: 1200px) {
      .sectionHeader .sectionHeader-image {
        -webkit-flex: 0 1 67%;
        -moz-flex: 0 1 67%;
        -ms-flex: 0 1 67%;
        -webkit-flex: 0 1 67%;
        flex: 0 1 67%;
        max-width: 67%;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        overflow: hidden; } }
    @media (min-width: 1400px) {
      .sectionHeader .sectionHeader-image {
        -webkit-flex: 0 1 68.5%;
        -moz-flex: 0 1 68.5%;
        -ms-flex: 0 1 68.5%;
        -webkit-flex: 0 1 68.5%;
        flex: 0 1 68.5%;
        max-width: 68.5%; } }
    .sectionHeader .sectionHeader-image:before {
      content: "";
      display: block;
      padding-top: 86.5%; }
      @media (min-width: 768px) {
        .sectionHeader .sectionHeader-image:before {
          padding-top: 52.5%; } }
      @media (min-width: 1200px) {
        .sectionHeader .sectionHeader-image:before {
          padding-top: 45%; } }
      @media (min-width: 1400px) {
        .sectionHeader .sectionHeader-image:before {
          padding-top: 45%; } }
    .sectionHeader .sectionHeader-image-inner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center; }
      @media (min-width: 1200px) {
        .sectionHeader .sectionHeader-image-inner {
          top: -5rem;
          left: -10rem;
          width: calc(100% + 10rem);
          height: calc(100% + 10rem);
          animation: imageSlide 20s linear forwards;
          will-change: transform; } }
  .sectionHeader .sectionHeader-content {
    padding: 0 0 4rem 0; }
    @media (min-width: 992px) {
      .sectionHeader .sectionHeader-content {
        padding: 7rem 3.5rem 3.5rem 3.5rem; } }
    .sectionHeader .sectionHeader-content h1 {
      position: relative;
      z-index: 1;
      left: 0;
      text-shadow: 0 0 38px rgba(0, 0, 0, 0.1);
      margin-bottom: 3rem;
      opacity: 0;
      animation: titleFadeIn 750ms linear forwards;
      animation-delay: 500ms; }
      @media (min-width: 1200px) {
        .sectionHeader .sectionHeader-content h1 {
          left: -9.5rem; } }
    .sectionHeader .sectionHeader-content .sectionHeader-text {
      opacity: 0;
      animation: textFadeIn 750ms linear forwards;
      animation-delay: 1.5s; }
      @media (min-width: 992px) {
        .sectionHeader .sectionHeader-content .sectionHeader-text {
          max-width: 350px; } }

@keyframes imageSlide {
  0% {
    transform: translate3d(10rem, 0, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes textFadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes titleFadeIn {
  0% {
    top: -5rem;
    opacity: 0; }
  100% {
    top: 0;
    opacity: 1; } }

@media (min-width: 992px) {
  .IE11 .widget-image-right {
    display: block !important; } }

@media (min-width: 992px) {
  .IE11 .widget-image-right-picture {
    margin-left: auto;
    margin-right: 0;
    position: relative; } }

.IE11 .widget-image-right-content-image img,
.IE11 .widget-image-left-content-image img,
.IE11 .widget-image-left-picture {
  width: 100%; }

@media (min-width: 1200px) {
  .IE11 .scroll-helper {
    max-height: 100% !important; } }

@media (min-width: 1200px) {
  .IE11 .category-top-banner-content-title {
    padding-left: 100%; } }

@media (min-width: 1200px) {
  .IE11 .category-top-banner-content-title .category-banner-title {
    transform: translateX(-100%) !important; } }

@media (min-width: 1200px) {
  .IE11 .category-top-banner-content-title .category-banner-title.multi-line, .IE11 .category-top-banner-content-title .category-banner-title.one-line {
    display: block !important;
    position: relative; } }

@media (min-width: 1200px) {
  .IE11 .category-top-banner-content-title .category-banner-title.multi-line .scroll-to-next-title, .IE11 .category-top-banner-content-title .category-banner-title.one-line .scroll-to-next-title {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(100%, 0%); } }

.edge #map-seznam .map-pin,
.browser-edge #map-seznam .map-pin {
  width: auto !important; }

.products {
  padding-bottom: 10.5rem; }
  .products .pux-container {
    padding-left: 15px;
    padding-right: 15px; }
    .products .pux-container .row {
      margin: 0 -20px; }
      .products .pux-container .row .col-xl-3,
      .products .pux-container .row .col-md-4,
      .products .pux-container .row .col-sm-6,
      .products .pux-container .row .col-12 {
        padding: 0 15px; }

.product-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 8px 65px rgba(0, 0, 0, 0.1);
  height: auto;
  padding: 0 4rem 3rem 4rem;
  margin-top: 8rem;
  margin-bottom: 30px;
  transition: box-shadow ease 300ms; }
  .product-card:hover {
    box-shadow: 0 8px 65px rgba(0, 0, 0, 0.2); }
    .product-card:hover .product-card-body .link:after {
      left: 15px; }
  .product-card .product-card-body {
    width: 100%; }
    .product-card .product-card-body .link {
      color: #e5004c;
      font-style: italic;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0.3px; }
      .product-card .product-card-body .link:after {
        position: relative;
        left: 10px;
        content: '\e902';
        font-size: 11px;
        transition: left ease 300ms; }

.product-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: -9rem;
  margin-bottom: 2rem; }
  @media (min-width: 320px) {
    .product-image {
      height: 30rem !important; } }
  @media (min-width: 480px) {
    .product-image {
      height: 27.27273rem !important; } }
  @media (min-width: 545px) {
    .product-image {
      height: 25rem !important; } }
  @media (min-width: 768px) {
    .product-image {
      height: 20rem !important; } }
  .product-image img {
    flex-grow: 0;
    flex-shrink: 0; }
    @media (min-width: 320px) {
      .product-image img {
        max-height: 30rem !important; } }
    @media (min-width: 480px) {
      .product-image img {
        max-height: 27.27273rem !important; } }
    @media (min-width: 545px) {
      .product-image img {
        max-height: 25rem !important; } }
    @media (min-width: 768px) {
      .product-image img {
        max-height: 20rem !important; } }

.product-name {
  display: flex;
  align-items: center;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.46px;
  margin-bottom: 5px; }
  @media (min-width: 320px) {
    .product-name {
      min-height: 7.2rem; } }
  @media (min-width: 480px) {
    .product-name {
      min-height: 6.54545rem; } }
  @media (min-width: 545px) {
    .product-name {
      min-height: 6rem; } }
  @media (min-width: 768px) {
    .product-name {
      min-height: 4.8rem; } }
  @media (min-width: 992px) {
    .product-name {
      min-height: 4.6rem; } }
  @media (min-width: 320px) {
    .product-name {
      font-size: 3.6rem; } }
  @media (min-width: 480px) {
    .product-name {
      font-size: 3.27273rem; } }
  @media (min-width: 545px) {
    .product-name {
      font-size: 3rem; } }
  @media (min-width: 768px) {
    .product-name {
      font-size: 2.4rem; } }
  @media (min-width: 992px) {
    .product-name {
      font-size: 2.3rem; } }
  @media (min-width: 320px) {
    .product-name {
      line-height: 3.6rem; } }
  @media (min-width: 480px) {
    .product-name {
      line-height: 3.27273rem; } }
  @media (min-width: 545px) {
    .product-name {
      line-height: 3rem; } }
  @media (min-width: 768px) {
    .product-name {
      line-height: 2.4rem; } }
  @media (min-width: 992px) {
    .product-name {
      line-height: 2.3rem; } }

.partners {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
  margin-bottom: 12rem;
  box-shadow: 0 8px 65px rgba(0, 0, 0, 0.1); }

.partners-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  background-color: #fff;
  min-height: 36rem;
  padding: 3rem 4rem 7rem 4rem;
  transition: box-shadow ease 300ms; }
  @media (min-width: 768px) {
    .partners-card {
      max-width: 33.3333%; } }
  @media (min-width: 1400px) {
    .partners-card {
      padding: 3rem 4rem 9rem 4rem; } }
  .partners-card:hover {
    z-index: 2;
    box-shadow: 0 8px 65px rgba(0, 0, 0, 0.2); }
    .partners-card:hover .partners-link:after {
      left: 15px; }
  .partners-card .partners-image {
    margin-bottom: 4rem; }
  .partners-card .partners-link {
    color: #e5004c;
    font-style: italic;
    font-weight: 400;
    letter-spacing: 0.3px; }
    @media (min-width: 320px) {
      .partners-card .partners-link {
        font-size: 3rem; } }
    @media (min-width: 480px) {
      .partners-card .partners-link {
        font-size: 2.72727rem; } }
    @media (min-width: 545px) {
      .partners-card .partners-link {
        font-size: 2.5rem; } }
    @media (min-width: 768px) {
      .partners-card .partners-link {
        font-size: 2rem; } }
    .partners-card .partners-link:after {
      position: relative;
      left: 10px;
      content: '\e902';
      transition: left ease 300ms; }
      @media (min-width: 320px) {
        .partners-card .partners-link:after {
          font-size: 2.2rem; } }
      @media (min-width: 480px) {
        .partners-card .partners-link:after {
          font-size: 2rem; } }
      @media (min-width: 545px) {
        .partners-card .partners-link:after {
          font-size: 1.83333rem; } }
      @media (min-width: 768px) {
        .partners-card .partners-link:after {
          font-size: 1.6rem; } }

.salers-stores {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  padding: 4rem 0 8rem 0; }
  @media (min-width: 768px) {
    .salers-stores {
      margin: 0; } }
  @media (min-width: 992px) {
    .salers-stores {
      flex-wrap: nowrap; } }
  .salers-stores-map {
    display: flex;
    width: 100%; }
    @media (min-width: 992px) {
      .salers-stores-map {
        width: 66%; } }
    .salers-stores-map-box {
      width: 100%;
      min-height: 60rem;
      margin-top: 3.5rem; }
      @media (min-width: 992px) {
        .salers-stores-map-box {
          margin-top: 0; } }
    .salers-stores-map-item {
      width: 100%;
      height: 100%;
      border-radius: 0.4rem;
      background-size: cover !important;
      background-repeat: no-repeat !important; }
  .salers-stores-list {
    display: block;
    width: 100%;
    padding: 0 2.5rem; }
    @media (min-width: 768px) {
      .salers-stores-list {
        padding: 0; } }
    @media (min-width: 992px) {
      .salers-stores-list {
        width: 33%;
        margin: 0 3.5rem 0 0; } }
    .salers-stores-list-search, .salers-stores-list-results {
      background: #fff; }
    .salers-stores-list-search {
      margin-bottom: 3.4rem;
      padding: 2.5rem;
      display: flex;
      flex-wrap: nowrap; }
      .salers-stores-list-search-txt {
        width: calc(100% - 5.6rem);
        padding: 1.7rem;
        background: #e4e4e4;
        outline: none;
        border: none;
        color: #000001; }
      .salers-stores-list-search-btn {
        padding: 1.7rem;
        margin: auto;
        cursor: pointer; }
        .salers-stores-list-search-btn:before {
          content: "\e902";
          color: #fff;
          font-weight: 300; }
          @media (min-width: 320px) {
            .salers-stores-list-search-btn:before {
              font-size: 3.6rem; } }
          @media (min-width: 480px) {
            .salers-stores-list-search-btn:before {
              font-size: 3.27273rem; } }
          @media (min-width: 545px) {
            .salers-stores-list-search-btn:before {
              font-size: 3rem; } }
          @media (min-width: 768px) {
            .salers-stores-list-search-btn:before {
              font-size: 2.4rem; } }
          @media (min-width: 992px) {
            .salers-stores-list-search-btn:before {
              font-size: 2.25rem; } }
          @media (min-width: 1200px) {
            .salers-stores-list-search-btn:before {
              font-size: 2.2rem; } }
    .salers-stores-list-results {
      padding: 3.5rem 3.5rem 0.5rem 3.5rem; }
  .salers-stores-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    padding: 2.3rem 0;
    border-bottom: 0.5px #e4e4e4 solid; }
    .salers-stores-item-address {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-right: 1rem;
      width: 100%; }
      .salers-stores-item-address b {
        color: #e5004c; }
      .salers-stores-item-address .btn {
        align-self: flex-end;
        padding: 1rem 0;
        margin: 0.5rem 0 0 0; }
        @media (min-width: 320px) {
          .salers-stores-item-address .btn {
            font-size: 2.4rem; } }
        @media (min-width: 480px) {
          .salers-stores-item-address .btn {
            font-size: 2.18182rem; } }
        @media (min-width: 545px) {
          .salers-stores-item-address .btn {
            font-size: 2rem; } }
        @media (min-width: 768px) {
          .salers-stores-item-address .btn {
            font-size: 1.6rem; } }
        @media (min-width: 992px) {
          .salers-stores-item-address .btn {
            font-size: 1.5rem; } }
        @media (min-width: 1200px) {
          .salers-stores-item-address .btn {
            font-size: 1.4rem; } }

#map-seznam {
  width: 100%;
  height: 100%;
  min-height: 100rem; }
  #map-seznam img {
    max-width: none !important; }
  #map-seznam .map-pin {
    position: relative;
    height: 55px;
    width: 55px; }
    #map-seznam .map-pin:focus, #map-seznam .map-pin:hover {
      z-index: 30; }
  #map-seznam .card-header:empty,
  #map-seznam .card-footer:empty {
    display: none; }
  #map-seznam .card {
    overflow: unset;
    max-width: 350px !important;
    padding: 4rem 5.5rem 6rem 5.5rem !important;
    border: none !important;
    border-radius: 0;
    margin-left: 152px;
    margin-bottom: 37px; }
    #map-seznam .card .card-body {
      padding: 0;
      flex: none; }
    #map-seznam .card .close {
      top: 10px !important;
      right: 10px !important;
      font-size: 20px;
      color: #e5004c;
      opacity: 1 !important; }
      #map-seznam .card .close:hover {
        color: #b2003b; }
      #map-seznam .card .close:after {
        display: none !important; }
      #map-seznam .card .close:before {
        content: '\2716' !important; }
  #map-seznam .zoom .zoom-menu {
    top: 32rem !important; }
    @media (min-width: 768px) {
      #map-seznam .zoom .zoom-menu {
        top: 26px !important; } }
  #map-seznam .button-group {
    top: 70rem; }
    @media (min-width: 768px) {
      #map-seznam .button-group {
        top: auto; } }
  #map-seznam .compass.compass-north {
    top: 79rem !important; }
    @media (min-width: 768px) {
      #map-seznam .compass.compass-north {
        top: 50px !important; } }

.pux-cluster div {
  background: #e5004c;
  border: none !important;
  color: #fff !important;
  width: 82px !important;
  height: 82px !important; }

.map-points {
  display: none; }

.dealers-item-data {
  color: #000;
  font-size: 17px;
  font-weight: 200;
  line-height: 32px; }
  .dealers-item-data a {
    color: #e5004c;
    text-decoration: underline; }
    .dealers-item-data a:focus, .dealers-item-data a:hover {
      text-decoration: none; }

.dealers-item-data-name {
  font-size: 20px;
  font-weight: 500; }

.holder {
  margin: 1.5rem 0; }

.holder {
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 320px) {
    .holder {
      font-size: 2.8rem; } }
  @media (min-width: 480px) {
    .holder {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .holder {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .holder {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .holder {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .holder {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .holder {
      font-size: 1.5rem; } }
  .holder .jp-first, .holder .jp-previous, .holder .jp-next, .holder .jp-last {
    position: relative;
    display: block;
    width: 20px;
    color: #e5004c;
    opacity: 1;
    transition: opacity ease 300ms, color ease 300ms; }
    @media (min-width: 320px) {
      .holder .jp-first, .holder .jp-previous, .holder .jp-next, .holder .jp-last {
        height: 2.8rem; } }
    @media (min-width: 480px) {
      .holder .jp-first, .holder .jp-previous, .holder .jp-next, .holder .jp-last {
        height: 2.54545rem; } }
    @media (min-width: 545px) {
      .holder .jp-first, .holder .jp-previous, .holder .jp-next, .holder .jp-last {
        height: 2.33333rem; } }
    @media (min-width: 768px) {
      .holder .jp-first, .holder .jp-previous, .holder .jp-next, .holder .jp-last {
        height: 1.86667rem; } }
    @media (min-width: 992px) {
      .holder .jp-first, .holder .jp-previous, .holder .jp-next, .holder .jp-last {
        height: 1.75rem; } }
    @media (min-width: 1200px) {
      .holder .jp-first, .holder .jp-previous, .holder .jp-next, .holder .jp-last {
        height: 1.55556rem; } }
    @media (min-width: 1400px) {
      .holder .jp-first, .holder .jp-previous, .holder .jp-next, .holder .jp-last {
        height: 1.5rem; } }
    .holder .jp-first:hover, .holder .jp-previous:hover, .holder .jp-next:hover, .holder .jp-last:hover {
      color: #cc0044; }
    .holder .jp-first.jp-disabled, .holder .jp-previous.jp-disabled, .holder .jp-next.jp-disabled, .holder .jp-last.jp-disabled {
      opacity: 0;
      cursor: default; }
  .holder a {
    margin: 0 8px; }
    .holder a.jp-current {
      font-weight: 700; }
    .holder a:hover {
      font-weight: 700;
      color: #000001; }
  .holder .jp-first {
    margin: 0 2rem 0 1rem; }
    .holder .jp-first:before {
      position: absolute;
      bottom: 1px;
      left: 0;
      content: "\e902";
      transform: scaleX(-1); }
    .holder .jp-first:after {
      position: absolute;
      bottom: 1px;
      right: -2px;
      content: "\e902";
      transform: scaleX(-1); }
  .holder .jp-previous {
    margin: 0 2rem 0 0; }
    .holder .jp-previous:before {
      position: absolute;
      bottom: 1px;
      left: 0;
      content: "\e902";
      transform: scaleX(-1); }
  .holder .jp-next {
    margin: 0 0 0 2rem; }
    .holder .jp-next:after {
      position: absolute;
      bottom: 1px;
      right: 0;
      content: "\e902"; }
  .holder .jp-last {
    margin: 0 1rem 0 2rem; }
    .holder .jp-last:before {
      position: absolute;
      bottom: 1px;
      left: -2px;
      content: "\e902"; }
    .holder .jp-last:after {
      position: absolute;
      bottom: 1px;
      right: 0;
      content: "\e902"; }

.rules {
  text-align: center;
  padding-top: 4rem;
  padding-bottom: 20rem; }
  .rules .checkbox {
    margin: 3rem 0; }
  @media (min-width: 320px) {
    .rules .link {
      font-size: 3.2rem; } }
  @media (min-width: 480px) {
    .rules .link {
      font-size: 2.90909rem; } }
  @media (min-width: 545px) {
    .rules .link {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    .rules .link {
      font-size: 2.13333rem; } }
  @media (min-width: 992px) {
    .rules .link {
      font-size: 2rem; } }
  .rules .link:hover {
    color: #e5004c;
    text-decoration: underline; }

.btn-rules {
  display: none;
  background-color: #e5004c;
  color: #fff;
  padding: 1.5rem 3rem; }
  .btn-rules:focus, .btn-rules:hover {
    text-decoration: none;
    background-color: #cc0044;
    color: #fff; }
  .btn-rules:disabled {
    background-color: #ff6698; }
    .btn-rules:disabled:focus, .btn-rules:disabled:hover {
      background-color: #ff6698;
      cursor: not-allowed; }
  .btn-rules.is-visible {
    display: inline-block; }

@media print {
  header,
  footer {
    display: none; } }
