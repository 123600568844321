
$px-values: (5px, 10px, 15px, 20px, 25px, 30px, 35px, 40px, 45px, 50px, 55px, 60px, 65px, 70px, 75px, 80px, 85px, 90px, 95px, 100px);

// bootstrap
$main-left: 10px;
$main-right: 10px;
$main-left-md: 10px;
$main-right-md: 10px;

// container
$container-max-width: 156rem;
$container-padding-left: $main-left;
$container-padding-right: $main-right;
$container-padding-left-md: $main-left-md;
$container-padding-right-md: $main-right-md;

// fluid container
$fluid-container-padding-left: $main-left;
$fluid-container-padding-right: $main-right;
$fluid-container-padding-left-md: $main-left-md;
$fluid-container-padding-right-md: $main-right-md;
$fluid-container-padding-left-xl: 0;
$fluid-container-padding-right-xl: 0;


// row
$row-margin-left: (-$main-left);
$row-margin-right: (-$main-right);
$row-margin-left-md: (-$main-left-md);
$row-margin-right-md: (-$main-right-md);

// col
$col-padding-left: $main-left;
$col-padding-right: $main-right;
$col-padding-left-md: $main-left-md;
$col-padding-right-md: $main-right-md;

// typography
$font-family: 'uni-sans', sans-serif;
$font-open: 'Open Sans', sans-serif;
$font-size: 16px;

// perex
$font-size-perex: 22px;
$min-font-size-perex: 18px;
$line-height-perex: 26px;
$min-line-height-perex: 22px;

// small text
$font-size-small: 14px;
$min-font-size-small: 14px;
$line-height-small: 18px;
$min-line-height-small: 18px;

// main colors
$base-black: #000001;
$base-white: #fff;
$color-link: $base-black;
$main-color: #e5004c;
$second-color: #a00136;
$color-line: #f3f3f3;
$color-grey: #e4e4e4;
$color-gray-2: #f5f5f5;
$color-gray-3: #dadada;
$color-dark-gray: #1f1f1f;
$color-blue: #599bd0;
$color-orange: #dd834c;
$color-pink: #ff8eb4;
$grey-line: #ebebeb;

// button primary
$btn-primary-background: transparent;
$btn-primary-color: $main-color;

// button default
$btn-default-background: $main-color;
$btn-default-color: $base-black;

// ul, li
$ul-bullet: $base-black;
$ol-number: $main-color;

// table
$table-even-background: #f8f8f8;
$table-tr-border: #f1f1f1;

// menu
$hamburger-width: 6rem;
$menu-break: "md";

// form
$color-placeholder: #000;

// inputs
$border-color: $base-white;
$input-background: $base-white;
$focus-border-color: $main-color;
$input-height: 4rem;
$textarea-height: $input-height * 3;

// selectric
$select-background: $input-background;
$select-color: $base-black;
$select-border: $input-background;
$select-border-focus: $main-color;
$select-arrow-color: $base-black;
$select-arrow-code: "\e901";
$select-use-triangle: 0;
$select-height: $input-height;

// datepicker
$datepick-today-background: $second-color;
$datepick-today-color:$base-white;
$datepick-active-background: $main-color;
$datepick-active-color: $base-white;

// datepicker
$datepicker-header-background: darken($main-color, 5);
$datepicker-header-color: $base-white;
$datepicker-header-arrows-color: $base-white;
$datepicker-option-backgorund:$main-color;
$datepicker-option-color: $base-white;
$datepicker-selected-background: $main-color;
$datepicker-selected-color: $base-white;
$datepicker-full-background: $base-white;
$datepicker-th-color: #757575 ;
$datepicker-th-border: #e9ecef;
$datepicker-year-picker-color: $main-color;

// upload
$upload-background: $main-color;
$upload-color: $base-white;
$file-name-background: $second-color;
$file-name-color: $base-black;

// form radio
$radio-background: $base-white;
$radio-border: #c3c2c2;
$radio-active-border: $main-color;
$radio-after-background: $main-color;

// form checkbox
$checkbox-background: $base-white;
$checkbox-border: #c3c2c2;
$checkbox-active-border: #c3c2c2;
$checkbox-after-background: $main-color;

// form error
$error-input-border: #d40511;
$error-label-color: #d40511;
$error-blink-start: #fff;
$error-blink-final: #fec7ca;
$use-blink-error: 1;

//scale ratios
$scale-base-size: 10px;

$scale-ratio-xxs: 0.5;
$scale-ratio-xs: 0.55;
$scale-ratio-sm: 0.6;
$scale-ratio-md: 0.75;
$scale-ratio-lg:  0.8;
$scale-ratio-xl: 0.9;
$scale-ratio-xxl: 1;

// scale
$scale-xxl: 1;
$scale-xl: 0.8;
$scale-lg: 0.7;
$scale-md: 0.6;
$scale-sm: 0.5;
$scale-xs: 0.4;
$scale-xxs: 0.4;

$min-font-size: 14px;
$min-padding: 10px;
$min-margin: 10px;

$body-bg: #dfdfdf;
$light-grey: #e9e9e9;

//arrays
$page-icon-names: (
	stores: "\e913",
	download: "\e911",
	service: "\e914",
  contact: "\e912",
	recept: "\e91e",
	review: "\e91f",
	press: "\e920",
	history: "\e921"
);

//arrays
$category-icons: (
	audio-video-audio: "\e93f",
	audio-video-auto: "\e945",
	audio-video-camera: "\e94c",
	audio-video-phone: "\e954",
	audio-video-entertaiment: "\e958",
	home-home-garden: "\e940",
	home-fridge: "\e946",
	home-weather: "\e956",
	home-heating: "\e94b",
	home-ventilator: "\e95d",
	home-cleaner: "\e92f",
	home-iron: "\e95b",
	office-cabel: "\e942",
	office-calculator: "\e947",
	office-shredder: "\e952",
	kitchen-grill: "\e957",
	kitchen-kettles: "\e95c",
	kitchen-sandwiches-maker: "\e95a",
	tv-holders: "\e950",
	tv-signal-reception: "\e94a",
	tv-tv: "\e943",
	health-shaving: "\e941",
	health-body: "\e948",
	health-hair: "\e93c",
	health-health: "\e951",
	health-toothbrushers: "\e959"
);

//*** lazy load ***
$imgLoadAnimation: animateLoaded__ 0.1s ease-out forwards;
