@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";


.rules {
  text-align: center;
  padding-top: 4rem;
  padding-bottom: 20rem;

  .checkbox {
    margin: 3rem 0;
  }

  .checkbox input[type="checkbox"] + label:before {
    
  }

  .link {
    @include pux-scale-with-min('font-size', 20px, 16px)
    &:hover {
      color: $main-color;
      text-decoration: underline;
    }
  }
}

.btn-rules {
  display: none;
  background-color: $main-color;
  color: $base-white;
  padding: 1.5rem 3rem;
  &:focus,
  &:hover{
    text-decoration: none;
    background-color: darken($main-color, 5);
    color: $base-white;
  }
  &:disabled {
    background-color: lighten($main-color, 25);
    &:focus,
    &:hover{
      background-color: lighten($main-color, 25);
      cursor: not-allowed;
    }
  }
  &.is-visible {
    display: inline-block;
  }
}